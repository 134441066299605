import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';

export * as Plot from '@observablehq/plot';

const DEFAULT_LEGEND_REFS = {};

const PlotContainer = (props: any) => {
  const {
    drawPlot,
    className = '',
    legendRefs = DEFAULT_LEGEND_REFS,
    onPointerChange = null,
    ...restProps
  } = props;

  const ref: any = useRef();
  const { width, height } = useContainerDimensions(ref);

  useEffect(() => {
    if (width === 0 && height === 0) return;

    const plot = drawPlot({ width, height });

    if (plot === undefined) return;

    ref.current.append(plot);

    if (onPointerChange) {
      new MutationObserver((mutation) => {
        const findPointerID = (nodeList, depth = 1) => {
          for (const node of nodeList.values()) {
            if (node.dataset?.pointerId) {
              return node.dataset.pointerId;
            }

            if (depth > 0) {
              const pointerID = findPointerID(node.childNodes, depth - 1);

              if (pointerID) {
                return pointerID;
              }
            }
          }

          return null;
        };

        mutation.forEach((mutationRecord) => {
          if (mutationRecord.type === 'childList') {
            const addedPointerId = findPointerID(mutationRecord.addedNodes);
            const removedPointerId = findPointerID(mutationRecord.removedNodes);

            if (addedPointerId) {
              onPointerChange(addedPointerId);
            } else if (removedPointerId) {
              onPointerChange(null);
            }
          }
        });
      }).observe(plot, {
        childList: true,
        subtree: true,
      });
    }

    const legends = Object.entries(legendRefs).map(
      ([legendType, legendRef]: any) => {
        const legend = plot.legend(legendType);
        legendRef.current.append(legend);
        legendRef.current.classList.add('plot-container');
        return [legend, legendRef];
      },
    );

    // eslint-disable-next-line consistent-return
    return () => {
      plot.remove();
      legends.forEach(([legend, legendRef]) => {
        legend.remove();
        legendRef.current?.classList.remove('plot-container');
      });
    };
  }, [drawPlot, width, height, legendRefs, onPointerChange]);

  return (
    <div ref={ref} className={`plot-container ${className}`} {...restProps} />
  );
};

export const useContainerDimensions = (ref) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: ref.current?.offsetWidth,
      height: ref.current?.offsetHeight,
    });

    const handleResize = _.debounce(() => {
      setDimensions(getDimensions());
    }, 50);

    if (ref.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return dimensions;
};

export default PlotContainer;
