import {
  Box,
  Button,
  Card,
  CardContent,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { Styles } from '@material-ui/styles/withStyles';
import moment from 'moment';
import * as React from 'react';
import { FC } from 'react';
import {
  Link as AdminLink,
  AutocompleteArrayInput,
  BooleanInput,
  DateTimeInput,
  DeleteButton,
  Edit,
  EditProps,
  FieldProps,
  FormWithRedirect,
  FunctionField,
  PasswordInput,
  ReferenceArrayInput,
  SaveButton,
  SelectInput,
  TextField,
  TextInput,
  Toolbar,
  email,
  required,
  useGetIdentity,
  useGetList,
  useRefresh,
} from 'react-admin';
import { JsonField, JsonInput } from 'react-admin-json-view';
import Select from 'react-select';

import api from '../api';
import OrderedArrayInput from '../components/OrderedArrayInput';
import { matchSuggestion } from '../lesson/utils';
import { TESTING_VARIANTS } from '../unit/UnitEdit';
import AvatarField from './AvatarField';
import FullNameField from './FullNameField';
import { validatePasswords } from './UserCreate';
import { PHONE_OS_LIST, SUB_STATUS_LIST, USER_TYPE_LIST } from './UserList';

const revenueCatAppId = process.env.REACT_APP_REVENUE_CAT_APP_ID;

const UserEdit: FC<EditProps> = (props) => (
  <Edit undoable={false} title={<UserTitle />} component="div" {...props}>
    <UserForm />
  </Edit>
);

const entitlementIds = [
  {
    value: 'subscription',
    label: 'Basic Subscription',
  },
  {
    value: 'pro_subscription',
    label: 'Pro Subscription',
  },
];

const durationValues = [
  {
    value: 'daily',
    label: '24 hour access',
  },
  {
    value: 'weekly',
    label: '7 day access',
  },
  {
    value: 'monthly',
    label: '1 month (31-day) access',
  },
  {
    value: 'two_month',
    label: '2 month (61-day) access',
  },
  {
    value: 'three_month',
    label: '3 month (92-day) access',
  },
  {
    value: 'six_month',
    label: '6 month (183-day) access',
  },
  {
    value: 'yearly',
    label: '1 year (365-day) access',
  },
  {
    value: 'lifetime',
    label: '200 year access',
  },
];

export const styles: Styles<Theme, any> = {
  name: { display: 'inline-block' },
  url: { display: 'block' },
  image: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
  button: {
    marginRight: 10,
  },
  select: {
    flex: 1,
    marginRight: 10,
  },
};

const useStyles = makeStyles(styles);

const UserTitle: FC<FieldProps> = ({ record }) =>
  record ? <FullNameField record={record} size="32" /> : null;

const UserForm = (props: any) => {
  const classes = useStyles(props);
  const { record } = props;

  const { identity, loading: identityLoading } = useGetIdentity();
  const [chosenDuration, setChosenDuration] = React.useState(durationValues[0]);
  const [chosenEntitlement, setChosenEntitlement] = React.useState(
    entitlementIds[0],
  );
  const refresh = useRefresh();

  const durationSelectionChange = (value: any) => {
    setChosenDuration(value);
  };

  const entitlementSelectionChange = (value: any) => {
    setChosenEntitlement(value);
  };

  const resyncSubscription = async () => {
    const userId = record?.id;
    await api.refreshSub(userId);
    refresh();
  };

  const lockOrUnlockUnit = async (unitId: any, isUnlocked: boolean) => {
    const userId = record?.id;
    const postBody = {
      userId,
      unitId,
      action: isUnlocked ? 'remove' : 'add',
    };
    try {
      await api.addRemoveUserUnit(postBody);
    } catch (err) {
      console.log('err', err);
    }

    refresh();
  };

  const clearAllProgress = async () => {
    if (window.confirm('Are you sure you want to clear all progress?')) {
      const userId = record?.id;
      await api.clearAllProgress(userId);
      refresh();
    }
  };

  const grantInitialSubscription = async () => {
    const userId = record?.id;
    const duration = chosenDuration.value;
    const entitlement = chosenEntitlement.value;
    await api.grantPromoSub(userId, duration, entitlement);
    refresh();
  };

  const userCurriculumUnits = React.useMemo(
    () => record?.userCurriculumUnits?.[record?.curriculumId?.[0]],
    [record],
  );
  // console.log('userCurriculumUnits', userCurriculumUnits)
  const { data: unitData } = useGetList(
    'content/unit',
    {
      page: 1,
      perPage: 99999,
    },
    { field: 'id', order: 'DESC' },
    {
      ...(userCurriculumUnits
        ? {
            id: userCurriculumUnits,
          }
        : {
            curriculumId: record?.curriculumId?.[0] || null,
          }),
    },
  );
  // console.log('unitData', unitData)
  const primaryCurriculumUnitsList = React.useMemo(() => {
    const listOfIds = userCurriculumUnits || [];
    return listOfIds.map((i: any, j: number) => ({
      id: i,
      name: unitData?.[i]?.name,
      testName: unitData?.[i]?.testName,
      testingVariant: unitData?.[i]?.testingVariant,
      unitNumber: j + 1,
      curriculumId: unitData?.[i]?.curriculumId,
      curriculumName: unitData?.[i]?.Curriculum?.name,
      isUnlocked: record?.user_unit?.find(
        (userUnitItem: any) => userUnitItem.id === i,
      ),
    }));
  }, [userCurriculumUnits, record, unitData]);

  if (identityLoading) return <>Loading...</>;

  return (
    <FormWithRedirect
      {...props}
      validate={validatePasswords}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                  <Typography variant="h6" gutterBottom>
                    Identity
                  </Typography>
                  {props?.record?.profilePicture && (
                    <AvatarField
                      includeDelete
                      record={props.record}
                      size="200"
                    />
                  )}
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        source="firstName"
                        resource="users"
                        validate={requiredValidate}
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        source="lastName"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>
                  <TextInput
                    helperText={false}
                    type="email"
                    source="email"
                    resource="users"
                    validate={[email()]}
                    fullWidth
                  />
                  <Box mt="1em" />
                  <Typography variant="h6" gutterBottom>
                    Other Details
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={0.25} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Country Code"
                        source="phoneCountryCode"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Phone Number"
                        source="phoneNumber"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flex={0.333}
                      mr={{ xs: 0, sm: '0.5em' }}
                      mt={{ xs: '1em', lg: 0 }}
                    >
                      <div>
                        <BooleanInput
                          style={{ minWidth: 200 }}
                          source="pushPermission"
                          resource="users"
                          helperText={false}
                        />
                      </div>
                    </Box>
                    <Box
                      flex={2}
                      mr={{ xs: 0, sm: '0.5em' }}
                      mt={{ xs: '1em', lg: 0 }}
                    >
                      <TextInput
                        fullWidth
                        label="Push Token"
                        source="pushToken"
                        resource="users"
                        helperText={false}
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    mr={{ xs: 0, sm: '0.5em' }}
                    mt={{ xs: '1em', lg: 0 }}
                  >
                    <Box flexDirection="row" display="flex">
                      <Box>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                          gutterBottom
                        >
                          Reminder Time
                        </Typography>
                        <FunctionField
                          render={() =>
                            record?.reminderTime
                              ? moment(
                                  `${moment().format(
                                    'YYYY-MM-DD',
                                  )}T${record?.reminderTime?.slice(
                                    11,
                                    record?.reminderTime.length,
                                  )}`,
                                ).format('h:mm a')
                              : `Not Scheduled`
                          }
                          label="Daily Reminder Time"
                          style={{ fontSize: '1em' }}
                        />
                      </Box>
                      <Box ml={5}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                          gutterBottom
                        >
                          Latest Timezone Offset
                        </Typography>
                        {record?.latestTimeZone ||
                        record?.latestTimeZone === 0 ? (
                          <>
                            UTC
                            {record?.latestTimeZone === 0
                              ? ' '
                              : record?.latestTimeZone > 0
                                ? '+'
                                : ''}
                            <TextField
                              style={{ fontSize: '1em' }}
                              source="latestTimeZone"
                              label="Latest Timezone Offset"
                            />
                          </>
                        ) : (
                          'Not yet set'
                        )}
                      </Box>

                      <Box ml={5}>
                        <TextInput
                          fullWidth
                          disabled
                          label="Country Code"
                          source="country_code"
                          helperText={false}
                        />
                      </Box>
                      <Box ml={5}>
                        <TextInput
                          fullWidth
                          disabled
                          label="Region"
                          source="region"
                          helperText={false}
                        />
                      </Box>
                      <Box ml={5}>
                        <TextInput
                          fullWidth
                          disabled
                          label="City"
                          source="city"
                          helperText={false}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      mr={{ xs: 0, sm: '0.5em' }}
                      mt={{ xs: '1em', lg: 0 }}
                    >
                      <div>
                        <BooleanInput
                          style={{ minWidth: 200 }}
                          source="audioPermission"
                          resource="users"
                          helperText={false}
                        />
                      </div>
                    </Box>
                    <Box mr={{ xs: 0, sm: '0.5em' }} mt={{ xs: '1em', lg: 0 }}>
                      <SelectInput
                        label="Phone OS"
                        source="phoneOS"
                        resource="users"
                        helperText={false}
                        choices={PHONE_OS_LIST}
                        allowEmpty
                        disabled
                      />
                    </Box>
                    <Box mr={{ xs: 0, sm: '0.5em' }} mt={{ xs: '1em', lg: 0 }}>
                      <SelectInput
                        label="Original Phone OS"
                        source="originalPhoneOS"
                        resource="users"
                        helperText={false}
                        choices={PHONE_OS_LIST}
                        allowEmpty
                        disabled
                      />
                    </Box>
                  </Box>
                  {identity?.id !== record?.id && (
                    <>
                      <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box
                          display="flex"
                          alignItems="center"
                          mr={{ xs: 0, sm: '0.5em' }}
                          mt={{ xs: '1em', lg: 0 }}
                        >
                          <BooleanInput
                            style={{ minWidth: 200 }}
                            label="Active"
                            source="isActive"
                            resource="users"
                            helperText={false}
                          />
                        </Box>

                        <Box
                          mr={{ xs: 0, sm: '0.5em' }}
                          mt={{ xs: '1em', lg: 0 }}
                        >
                          <SelectInput
                            label="User Role"
                            source="role"
                            resource="users"
                            helperText={false}
                            choices={USER_TYPE_LIST}
                          />
                        </Box>

                        <Box
                          mr={{ xs: 0, sm: '0.5em' }}
                          mt={{ xs: '1em', lg: 0 }}
                        >
                          <SelectInput
                            label="Sub Status"
                            disabled
                            source="subStatus"
                            resource="users"
                            helperText={false}
                            choices={SUB_STATUS_LIST}
                          />
                        </Box>
                        <Box
                          mr={{ xs: 0, sm: '0.5em' }}
                          mt={{ xs: '1em', lg: 0 }}
                        >
                          <TextInput
                            label="Product"
                            disabled
                            source="subProduct"
                            resource="users"
                            helperText={false}
                            style={{ width: 400 }}
                          />
                        </Box>
                      </Box>
                      {(record?.role === 'admin' ||
                        record?.role === 'admin_read_only') && (
                        <Box display={{ xs: 'block', sm: 'flex' }}>
                          <Box
                            display="flex"
                            alignItems="center"
                            mr={{ xs: 0, sm: '0.5em' }}
                            mt={{ xs: '1em', lg: 0 }}
                            width="200px"
                          />
                          <TextInput
                            label="Impersonate User ID"
                            source="impersonateUserId"
                            resource="users"
                            helperText="This user must be an admin to impersonate another user."
                            style={{ width: 400 }}
                          />
                        </Box>
                      )}
                    </>
                  )}
                  <Box mt="1em" />
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      mr={{ xs: 0, sm: '0.5em' }}
                      mt={{ xs: '1em', lg: 0 }}
                    >
                      <BooleanInput
                        style={{ maxWidth: 200 }}
                        label="Skipped Trial during onboarding (sees discount)"
                        source="skippedTrial"
                        resource="users"
                        disabled
                        helperText={false}
                      />
                      <BooleanInput
                        style={{ minWidth: 200 }}
                        label="Subscriber"
                        source="isSubscriber"
                        resource="users"
                        disabled
                        helperText={false}
                      />
                      <BooleanInput
                        style={{ minWidth: 200 }}
                        label="Pro Subscriber"
                        source="isProSubscriber"
                        resource="users"
                        disabled
                        helperText={false}
                      />
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      flex={5}
                      mr={{ xs: 0, sm: '0.5em' }}
                      mt={{ xs: '1em', lg: 0 }}
                    >
                      {Object.keys(record?.subscriptionData || {}).length ? (
                        <JsonField
                          source="subscriptionData"
                          record={record}
                          jsonString={false}
                          reactJsonOptions={{
                            name: 'subscriptionData',
                            collapsed: true,
                            enableClipboard: true,
                            displayDataTypes: false,
                            sortKeys: true,
                            displayObjectSize: false,
                          }}
                        />
                      ) : (
                        <>
                          <Select
                            autosize
                            className={classes.select}
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 2 }),
                            }}
                            name="select-entitlement"
                            value={chosenEntitlement}
                            onChange={entitlementSelectionChange}
                            options={entitlementIds}
                          />
                          <Select
                            autosize
                            className={classes.select}
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 2 }),
                            }}
                            name="select-duration"
                            value={chosenDuration}
                            onChange={durationSelectionChange}
                            options={durationValues}
                          />
                        </>
                      )}
                    </Box>

                    <Box mr={{ xs: 0, sm: '0.5em' }} mt={{ xs: '1em', lg: 0 }}>
                      {Object.keys(record?.subscriptionData || {}).length ? (
                        <>
                          <Link
                            component={(buttonProps) => (
                              <Button
                                variant="contained"
                                color="primary"
                                {...buttonProps}
                              />
                            )}
                            href={`https://app.revenuecat.com/customers/${revenueCatAppId}/${record.id}`}
                            target="_blank"
                            style={{ marginRight: 10 }}
                          >
                            Manage Subscription in Revenue Cat
                          </Link>
                          <Button
                            onClick={resyncSubscription}
                            variant="contained"
                            color="primary"
                          >
                            Resync
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={grantInitialSubscription}
                          variant="contained"
                          color="primary"
                        >
                          Grant Initial Subscription
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    {record?.first_trial_or_purchase_date && (
                      <Box
                        display="flex"
                        alignItems="center"
                        mr={{ xs: 0, sm: '0.5em' }}
                        mt={{ xs: '1em', lg: 0 }}
                      >
                        <DateTimeInput
                          disabled
                          source="first_trial_or_purchase_date"
                          label="Trial Start/Initial Purchase"
                        />
                      </Box>
                    )}
                    {!moment(record?.last_purchase_date).isSame(
                      moment(record?.first_trial_or_purchase_date),
                      'day',
                    ) && (
                      <Box
                        display="flex"
                        alignItems="center"
                        mr={{ xs: 0, sm: '0.5em' }}
                        mt={{ xs: '1em', lg: 0 }}
                      >
                        <DateTimeInput
                          disabled
                          source="last_purchase_date"
                          label="Last Billed Date"
                        />
                      </Box>
                    )}
                    {record?.cancelled_date && (
                      <Box
                        display="flex"
                        alignItems="center"
                        mr={{ xs: 0, sm: '0.5em' }}
                        mt={{ xs: '1em', lg: 0 }}
                      >
                        <DateTimeInput
                          disabled
                          source="cancelled_date"
                          label="Cancelled"
                        />
                      </Box>
                    )}
                    {record?.failed_payment_date && (
                      <Box
                        display="flex"
                        alignItems="center"
                        mr={{ xs: 0, sm: '0.5em' }}
                        mt={{ xs: '1em', lg: 0 }}
                      >
                        <DateTimeInput
                          disabled
                          source="failed_payment_date"
                          label="Failed Payment"
                        />
                      </Box>
                    )}
                    {record?.subscription_renews_or_expires_date && (
                      <Box
                        display="flex"
                        alignItems="center"
                        mr={{ xs: 0, sm: '0.5em' }}
                        mt={{ xs: '1em', lg: 0 }}
                      >
                        <DateTimeInput
                          disabled
                          source="subscription_renews_or_expires_date"
                          label={
                            record?.subStatus === 'trial'
                              ? 'Converts'
                              : [
                                    'subscribed_not_renew',
                                    'trial_not_renew',
                                    'failed_payment',
                                  ].includes(record?.subStatus)
                                ? 'Expires'
                                : [
                                      'expired_trial',
                                      'expired_paid',
                                      'refunded',
                                    ].includes(record?.subStatus)
                                  ? 'Expired'
                                  : 'Renews'
                          }
                        />
                      </Box>
                    )}

                    {record?.grace_period_expires_date &&
                      !moment(
                        record?.subscription_renews_or_expires_date,
                      ).isSame(
                        moment(record?.grace_period_expires_date),
                        'day',
                      ) && (
                        <Box
                          display="flex"
                          alignItems="center"
                          mr={{ xs: 0, sm: '0.5em' }}
                          mt={{ xs: '1em', lg: 0 }}
                        >
                          <DateTimeInput
                            disabled
                            source="grace_period_expires_date"
                            label="Grace Period Expires"
                          />
                        </Box>
                      )}
                    {record?.refund_date && (
                      <Box
                        display="flex"
                        alignItems="center"
                        mr={{ xs: 0, sm: '0.5em' }}
                        mt={{ xs: '1em', lg: 0 }}
                      >
                        <DateTimeInput
                          disabled
                          source="refund_date"
                          label="Refunded"
                        />
                      </Box>
                    )}
                    {record?.subscription_duration_in_months && (
                      <Box
                        display="flex"
                        alignItems="center"
                        mr={{ xs: 0, sm: '0.5em' }}
                        mt={{ xs: '1em', lg: 0 }}
                      >
                        <TextInput
                          disabled
                          source="subscription_duration_in_months"
                          label="Sub Duration (Months)"
                        />
                      </Box>
                    )}
                    {record?.subscription_store && (
                      <Box
                        display="flex"
                        alignItems="center"
                        mr={{ xs: 0, sm: '0.5em' }}
                        mt={{ xs: '1em', lg: 0 }}
                      >
                        <TextInput
                          disabled
                          source="subscription_store"
                          label="Sub Store"
                        />
                      </Box>
                    )}
                  </Box>
                  <Box mt="1em" />
                  {Object.keys(record?.latestPurchaserInfo || {}).length ? (
                    <>
                      <Typography variant="body1" gutterBottom>
                        Note: Latest Purchaser Info is only refreshed when the
                        user interacts with the app, and old data may be cached
                        in the app up to 5 minutes after something has changed.
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        flex={5}
                        mr={{ xs: 0, sm: '0.5em' }}
                        mt={{ xs: '1em', lg: 0 }}
                      >
                        <JsonField
                          source="latestPurchaserInfo"
                          record={record}
                          jsonString={false}
                          reactJsonOptions={{
                            name: 'latestPurchaserInfo',
                            collapsed: true,
                            enableClipboard: true,
                            displayDataTypes: false,
                            sortKeys: true,
                            displayObjectSize: false,
                          }}
                        />
                      </Box>

                      <Box mt="1em" />
                    </>
                  ) : null}
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box style={{ flex: 1 }}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold', paddingBottom: 7.5 }}
                        gutterBottom
                      >
                        Feature Flags
                      </Typography>
                      <Box style={{ display: 'flex', flex: 1, gap: 10 }}>
                        <DateTimeInput
                          fullWidth
                          source="originalFirstAppOpen"
                          label="Original First App Open"
                        />
                        <TextInput
                          fullWidth
                          source="originalNativeAppVersion"
                          label="Original App Version"
                        />
                        <TextInput
                          fullWidth
                          disabled
                          source="featureFlagDistinctId"
                          label="Feature Flag Distinct ID"
                        />
                      </Box>
                      <JsonInput
                        source="featureFlags"
                        record={record}
                        jsonString={false}
                        reactJsonOptions={{
                          name: 'featureFlags',
                          collapsed: false,
                          enableClipboard: true,
                          displayDataTypes: false,
                          sortKeys: true,
                          displayObjectSize: false,
                        }}
                      />
                    </Box>
                    <Box style={{ flex: 1 }}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold', paddingBottom: 7.5 }}
                        gutterBottom
                      >
                        AppsFlyer Attribution Data
                      </Typography>
                      <JsonField
                        source="attributionData"
                        record={record}
                        jsonString={false}
                        reactJsonOptions={{
                          name: 'attributionData',
                          collapsed: false,
                          enableClipboard: true,
                          displayDataTypes: false,
                          sortKeys: true,
                          displayObjectSize: false,
                        }}
                      />
                    </Box>
                    {record?.startedFreeTrial && (
                      <DateTimeInput
                        disabled
                        source="startedFreeTrial"
                        label="Started Free Trial on No Paywall"
                      />
                    )}
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      mr={{ xs: 0, sm: '0.5em' }}
                      mt={{ xs: '1em', lg: 0 }}
                    >
                      <DateTimeInput
                        disabled
                        source="createdAt"
                        label="Joined"
                      />
                    </Box>

                    <Box mr={{ xs: 0, sm: '0.5em' }} mt={{ xs: '1em', lg: 0 }}>
                      <DateTimeInput
                        disabled
                        source="lastSeen"
                        label="Last Seen"
                      />
                    </Box>
                  </Box>
                  <Box mt="1em" />
                  <Typography variant="h6" gutterBottom>
                    Onboarding Details
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Language"
                        source="language"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Motivation"
                        source="motivation"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Referral Source"
                        source="referral"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="English Proficiency"
                        source="proficiency"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Accent Confidence"
                        source="pronunciation_accent"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Desired Improvement"
                        source="setting"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Will you commit?"
                        source="commit"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Occupation"
                        source="occupation"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Interests"
                        source="interests"
                        resource="users"
                        fullWidth
                        helperText={false}
                      />
                    </Box>
                  </Box>
                  <Box
                    display={{ xs: 'block', sm: 'flex' }}
                    style={{ marginTop: 10, marginBottom: 10 }}
                  >
                    <JsonField
                      source="ProvisionalUser"
                      record={record}
                      jsonString={false}
                      reactJsonOptions={{
                        name: 'Linked Provisionals',
                        collapsed: true,
                        enableClipboard: true,
                        displayDataTypes: false,
                        sortKeys: true,
                        displayObjectSize: false,
                      }}
                    />
                    <JsonField
                      source="redeemer"
                      record={record}
                      jsonString={false}
                      reactJsonOptions={{
                        name: 'Referral Redemption',
                        collapsed: true,
                        enableClipboard: true,
                        displayDataTypes: false,
                        sortKeys: true,
                        displayObjectSize: false,
                      }}
                    />
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <BooleanInput
                      style={{ minWidth: 200 }}
                      label="Unlock All?"
                      source="unlockAll"
                      resource="users"
                      helperText={false}
                    />
                    <BooleanInput
                      style={{ minWidth: 200 }}
                      label="Skip Redis Cache?"
                      source="skipRedisCache"
                      resource="users"
                      helperText={false}
                    />
                  </Box>
                  <Typography variant="h6" gutterBottom>
                    3rd party auth
                  </Typography>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput
                      disabled
                      label="Apple Identity Token"
                      source="appleId"
                      resource="users"
                      fullWidth
                      helperText={false}
                    />
                    <TextInput
                      disabled
                      label="Google Id"
                      source="googleId"
                      resource="users"
                      fullWidth
                      helperText={false}
                    />
                    <TextInput
                      disabled
                      label="Facebook Id"
                      source="facebookId"
                      resource="users"
                      fullWidth
                      helperText={false}
                    />
                  </Box>
                  <Box mt="1em" />
                  <Typography variant="h6" gutterBottom>
                    Change Password
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <PasswordInput
                        autoComplete="new-password"
                        source="password"
                        resource="users"
                        fullWidth
                      />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                      <PasswordInput
                        source="confirm_password"
                        resource="users"
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Box style={{ paddingBottom: 10 }}>
                    <ReferenceArrayInput
                      filterToQuery={(searchText: any) => ({
                        name: searchText,
                      })}
                      label="Curricula"
                      source="curriculumId"
                      reference="content/curriculum"
                      fullWidth
                    >
                      <OrderedArrayInput elementLinkUrl="/#/content/curriculum/">
                        <AutocompleteArrayInput
                          optionValue="id"
                          optionText="name"
                          matchSuggestion={(filterValue: string, choice: any) =>
                            matchSuggestion(filterValue, choice, 'name')
                          }
                        />
                      </OrderedArrayInput>
                    </ReferenceArrayInput>
                  </Box>
                  <Box style={{ marginTop: 10 }}>
                    <Typography variant="h6" gutterBottom align="left">
                      Assigned Units In Primary (1st) Curriculum
                    </Typography>
                    <DataGrid
                      autoHeight
                      rows={primaryCurriculumUnitsList}
                      columns={[
                        {
                          field: 'curriculumName',
                          headerName: 'Curriculum Name (current)',
                          flex: 1,
                          valueGetter: ({ row }) => row?.name,
                          renderCell: (params: any) => (
                            <AdminLink
                              to={`/content/curriculum/${params.row?.curriculumId}`}
                            >
                              <span className="table-cell-trucate">
                                {params.row?.curriculumName}
                              </span>
                            </AdminLink>
                          ),
                        },
                        {
                          field: 'unitName',
                          headerName: 'Unit Name',
                          flex: 1,
                          valueGetter: ({ row }) => row?.name,
                          renderCell: (params: any) => (
                            <AdminLink to={`/content/unit/${params.row?.id}`}>
                              <span className="table-cell-trucate">
                                {params.row?.name}
                              </span>
                            </AdminLink>
                          ),
                        },
                        {
                          field: 'unitNumber',
                          headerName: 'Unit #',
                          flex: 1,
                          valueGetter: ({ row }) => row?.unitNumber,
                          renderCell: (params: any) => (
                            <span className="table-cell-trucate">
                              {params.row?.unitNumber}
                            </span>
                          ),
                        },
                        {
                          field: 'testName',
                          headerName: 'Experiment Name',
                          flex: 1,
                          valueGetter: ({ row }) => row?.testName,
                          renderCell: (params: any) => (
                            <span className="table-cell-trucate">
                              {params.row?.testName}
                            </span>
                          ),
                        },
                        {
                          field: 'testingVariant',
                          headerName: 'Testing Variant',
                          flex: 1,
                          valueGetter: ({ row }) => row?.testingVariant,
                          renderCell: (params: any) => (
                            <span className="table-cell-trucate">
                              {params?.row?.testingVariant
                                ? TESTING_VARIANTS?.find(
                                    (i) => i.id === params.row.testingVariant,
                                  )?.name
                                : null}
                            </span>
                          ),
                        },
                        {
                          field: 'id',
                          headerName: 'Unlock / Lock Units',
                          flex: 1,
                          valueGetter: ({ row }) => row?.id,
                          renderCell: (params: any) => (
                            <span className="table-cell-trucate">
                              <Button
                                onClick={() =>
                                  lockOrUnlockUnit(
                                    params.row.id,
                                    params.row.isUnlocked,
                                  )
                                }
                                variant="contained"
                                color="primary"
                              >
                                {params.row.isUnlocked
                                  ? 'Lock Unit'
                                  : 'Unlock Unit'}
                              </Button>
                            </span>
                          ),
                        },
                      ]}
                    />
                  </Box>
                  <Box style={{ marginTop: 10 }}>
                    <Typography variant="h6" gutterBottom align="left">
                      Unlocked Units
                    </Typography>
                    <DataGrid
                      autoHeight
                      rows={record?.user_unit || []}
                      columns={[
                        {
                          field: 'curriculumId',
                          headerName: 'Curriculum',
                          flex: 1,
                          valueGetter: ({ row }) => row?.curriculumId,
                          renderCell: (params: any) => (
                            <AdminLink
                              to={`/content/curriculum/${params.row?.curriculumId}`}
                            >
                              <span className="table-cell-trucate">
                                {
                                  record?.curricula?.find(
                                    (i: any) =>
                                      i.id === params?.row?.curriculumId,
                                  )?.name
                                }
                              </span>
                            </AdminLink>
                          ),
                        },
                        {
                          field: 'unitName',
                          headerName: 'Unit Name',
                          flex: 1,
                          valueGetter: ({ row }) => row?.name,
                          renderCell: (params: any) => (
                            <AdminLink
                              to={`/content/unit/${params.row?.UserUnit?.unitId}`}
                            >
                              <span className="table-cell-trucate">
                                {params.row?.name}
                              </span>
                            </AdminLink>
                          ),
                        },
                        {
                          field: 'UserUnit.unitNumber',
                          headerName: 'Unit #',
                          flex: 1,
                          valueGetter: ({ row }) => row?.unitNumber,
                          renderCell: (params: any) => (
                            <span className="table-cell-trucate">
                              {params.row?.unitNumber}
                            </span>
                          ),
                        },
                        {
                          field: 'UserUnit.createdAt',
                          headerName: 'Unlocked On',
                          type: 'date',
                          flex: 1,
                          valueGetter: ({ row }) =>
                            moment(row?.UserUnit?.createdAt).format('lll'),
                          renderCell: (params: any) => (
                            <span className="table-cell-trucate">
                              {moment(params.row?.UserUnit?.createdAt).format(
                                'lll',
                              )}
                            </span>
                          ),
                        },
                      ]}
                    />
                  </Box>
                  <Box style={{ marginTop: 10 }}>
                    <Typography variant="h6" gutterBottom align="left">
                      Video Progress
                    </Typography>
                    <DataGrid
                      autoHeight
                      sortModel={[
                        {
                          field: 'updatedAt',
                          sort: 'desc',
                        },
                      ]}
                      columns={[
                        {
                          field: 'Video.name',
                          headerName: 'Video',
                          flex: 1,
                          valueGetter: ({ row }) => row?.Video?.name,
                          renderCell: (params: any) => (
                            <Tooltip title={params.row?.Video?.name}>
                              <span className="table-cell-trucate">
                                {params.row?.Video?.name}
                              </span>
                            </Tooltip>
                          ),
                        },
                        {
                          field: 'Lesson.name',
                          headerName: 'Lesson',
                          flex: 1,
                          valueGetter: ({ row }) => row?.Lesson?.name,
                          renderCell: (params: any) => (
                            <Tooltip title={params.row?.Lesson?.name}>
                              <span className="table-cell-trucate">
                                {params.row?.Lesson?.name}
                              </span>
                            </Tooltip>
                          ),
                        },
                        {
                          field: 'Unit.name',
                          headerName: 'Unit',
                          flex: 1,
                          valueGetter: ({ row }) => row?.Unit?.name,
                          renderCell: (params: any) => (
                            <Tooltip title={params.row?.Unit?.name}>
                              <span className="table-cell-trucate">
                                {params.row?.Unit?.name}
                              </span>
                            </Tooltip>
                          ),
                        },
                        {
                          field: 'Curriculum.name',
                          headerName: 'Curriculum',
                          flex: 1,
                          valueGetter: ({ row }) => row?.Curriculum?.name,
                          renderCell: (params: any) => (
                            <Tooltip title={params.row?.Curriculum?.name}>
                              <span className="table-cell-trucate">
                                {params.row?.Curriculum?.name}
                              </span>
                            </Tooltip>
                          ),
                        },
                        {
                          field: 'percentCompleted',
                          headerName: 'Percent Completed',
                          flex: 1,
                          valueGetter: ({ row }) => `${row?.percentCompleted}%`,
                          renderCell: (params: any) => (
                            <Tooltip title={`${params.row?.percentCompleted}%`}>
                              <span className="table-cell-trucate">
                                {params.row?.percentCompleted}%
                              </span>
                            </Tooltip>
                          ),
                        },
                        {
                          headerName: 'Created At',
                          field: 'createdAt',
                          flex: 1,
                          type: 'date',
                          valueFormatter: ({ row }) =>
                            moment(row?.createdAt).format('lll'),
                          renderCell: (params: any) => (
                            <Tooltip
                              title={moment(params.row?.createdAt).format(
                                'lll',
                              )}
                            >
                              <span className="table-cell-trucate">
                                {moment(params.row?.createdAt).format('lll')}
                              </span>
                            </Tooltip>
                          ),
                        },
                        {
                          headerName: 'Updated At',
                          field: 'updatedAt',
                          flex: 1,
                          type: 'date',
                          valueFormatter: ({ row }) =>
                            moment(row?.updatedAt).format('lll'),
                          renderCell: (params: any) => (
                            <Tooltip
                              title={moment(params.row?.updatedAt).format(
                                'lll',
                              )}
                            >
                              <span className="table-cell-trucate">
                                {moment(params.row?.updatedAt).format('lll')}
                              </span>
                            </Tooltip>
                          ),
                        },
                      ]}
                      rows={record?.VideoProgress || []}
                    />
                  </Box>
                  <Box style={{ marginTop: 10 }}>
                    <Typography variant="h6" gutterBottom align="left">
                      Practice Progress
                    </Typography>
                    <DataGrid
                      autoHeight
                      sortModel={[
                        {
                          field: 'updatedAt',
                          sort: 'desc',
                        },
                      ]}
                      columns={[
                        {
                          field: 'Practice.name',
                          headerName: 'Practice Item',
                          flex: 1,
                          valueGetter: ({ row }) => row?.Practice?.word,
                          renderCell: (params: any) => (
                            <Tooltip title={params.row?.Practice?.word}>
                              <span className="table-cell-trucate">
                                {params.row?.Practice?.word}
                              </span>
                            </Tooltip>
                          ),
                        },
                        {
                          field: 'Lesson.name',
                          headerName: 'Lesson',
                          flex: 1,
                          valueGetter: ({ row }) => row?.Lesson?.name,
                          renderCell: (params: any) => (
                            <Tooltip title={params.row?.Lesson?.name}>
                              <span className="table-cell-trucate">
                                {params.row?.Lesson?.name}
                              </span>
                            </Tooltip>
                          ),
                        },
                        {
                          field: 'Unit.name',
                          headerName: 'Unit',
                          flex: 1,
                          valueGetter: ({ row }) => row?.Unit?.name,
                          renderCell: (params: any) => (
                            <Tooltip title={params.row?.Unit?.name}>
                              <span className="table-cell-trucate">
                                {params.row?.Unit?.name}
                              </span>
                            </Tooltip>
                          ),
                        },
                        {
                          field: 'Curriculum.name',
                          headerName: 'Curriculum',
                          flex: 1,
                          valueGetter: ({ row }) => row?.Curriculum?.name,
                          renderCell: (params: any) => (
                            <Tooltip title={params.row?.Curriculum?.name}>
                              <span className="table-cell-trucate">
                                {params.row?.Curriculum?.name}
                              </span>
                            </Tooltip>
                          ),
                        },
                        {
                          field: 'completed',
                          headerName: 'Completed',
                          flex: 0.75,
                          valueGetter: ({ row }) =>
                            row.completed ? 'Yes' : 'No',
                          renderCell: (params: any) => (
                            <Tooltip
                              title={params.row.completed ? 'Yes' : 'No'}
                            >
                              <span className="table-cell-trucate">
                                {params.row.completed ? 'Yes' : 'No'}
                              </span>
                            </Tooltip>
                          ),
                        },
                        {
                          field: 'inVideo',
                          headerName: 'In Video',
                          flex: 0.65,
                          valueGetter: ({ row }) =>
                            row.inVideo ? 'Yes' : 'No',
                          renderCell: (params: any) => (
                            <Tooltip title={params.row.inVideo ? 'Yes' : 'No'}>
                              <span className="table-cell-trucate">
                                {params.row.inVideo ? 'Yes' : 'No'}
                              </span>
                            </Tooltip>
                          ),
                        },
                        {
                          headerName: 'Created At',
                          field: 'createdAt',
                          flex: 1,
                          type: 'date',
                          valueFormatter: ({ row }) =>
                            moment(row?.createdAt).format('lll'),
                          renderCell: (params: any) => (
                            <Tooltip
                              title={moment(params.row?.createdAt).format(
                                'lll',
                              )}
                            >
                              <span className="table-cell-trucate">
                                {moment(params.row?.createdAt).format('lll')}
                              </span>
                            </Tooltip>
                          ),
                        },
                        {
                          headerName: 'Updated At',
                          field: 'updatedAt',
                          flex: 1,
                          type: 'date',
                          valueFormatter: ({ row }) =>
                            moment(row?.updatedAt).format('lll'),
                          renderCell: (params: any) => (
                            <Tooltip
                              title={moment(params.row?.updatedAt).format(
                                'lll',
                              )}
                            >
                              <span className="table-cell-trucate">
                                {moment(params.row?.updatedAt).format('lll')}
                              </span>
                            </Tooltip>
                          ),
                        },
                      ]}
                      rows={record?.PracticeProgress || []}
                    />
                  </Box>
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="users"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <SaveButton
                  label="Save & Continue"
                  handleSubmitWithRedirect={
                    formProps.handleSubmitWithRedirect || formProps.handleSubmit
                  }
                  disabled={formProps.disabled}
                  invalid={formProps.invalid}
                  redirect="edit"
                  saving={formProps.saving}
                  submitOnEnter={formProps.submitOnEnter}
                  className={classes.button}
                />

                <SaveButton
                  label="Save & Exit"
                  handleSubmitWithRedirect={
                    formProps.handleSubmitWithRedirect || formProps.handleSubmit
                  }
                  disabled={formProps.disabled}
                  invalid={formProps.invalid}
                  redirect={formProps.redirect}
                  saving={formProps.saving}
                  submitOnEnter={formProps.submitOnEnter}
                  className={classes.button}
                />
              </div>

              <div>
                <Button
                  onClick={clearAllProgress}
                  variant="contained"
                  color="primary"
                >
                  Clear All Progress
                </Button>

                {formProps.record &&
                  typeof formProps.record.id !== 'undefined' && (
                    <DeleteButton
                      basePath={formProps.basePath}
                      record={formProps.record}
                      resource={formProps.resource}
                      undoable={formProps.undoable}
                      mutationMode={formProps.mutationMode}
                    />
                  )}
              </div>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

const requiredValidate = [required()];

export default UserEdit;
