import { CardContent, CardHeader, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import * as React from 'react';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { CREATED_AT_TYPES } from '../engagement/EngagementDashboard';
import {
  MaterialHTMLTooltip,
  StyledCard,
  TitleParent,
  TotalParent,
} from './charts/Styled';
import DashboardToolTip from './tooltips/DashboardToolTip';

const useStyles = makeStyles(() => ({
  cardContent: {
    '& $svg.recharts-surface': {
      overflow: 'visible',
    },
    '& $span.recharts-tooltip-item-name': {
      whiteSpace: 'pre',
      flex: 1,
    },
    '& $span.recharts-tooltip-item-value': {
      whiteSpace: 'pre',
      flex: 1,
      textAlign: 'right',
    },
    '& $li.recharts-tooltip-item': {
      display: 'flex !important',
      flexDirection: 'row',
    },
  },
}));

const DATE_TYPE_LIST = [
  {
    id: 'original_purchase_date',
    name: 'Trial Start Date',
    value: 'original_purchase_date',
  },
  {
    id: 'signup_date',
    name: 'Sign Up Date',
    value: 'signup_date',
  },
];

const BAR_TYPE_LIST = [
  {
    id: 'signupsNum',
    name: 'Sign Ups',
    value: 'signupsNum',
  },
  {
    id: 'trialStartsNum',
    name: 'Trials',
    value: 'trialStartsNum',
  },
  {
    id: 'trialCancelledFromTrialStartNum',
    name: 'Cancelled',
    value: 'trialCancelledFromTrialStartNum',
  },
  {
    id: 'trialConvertedToPaidNum',
    name: 'Converted',
    value: 'trialConvertedToPaidNum',
  },
  {
    id: 'trialRefundedNum',
    name: 'Refunded',
    value: 'trialRefundedNum',
  },
  {
    id: 'trialFailedPaymentNum',
    name: 'Failed',
    value: 'trialFailedPaymentNum',
  },
  {
    id: 'trialPendingNum',
    name: 'Pending',
    value: 'trialPendingNum',
  },
];

const DashboardChart = React.memo((props: any) => {
  const {
    title,
    subtitle,
    data: incomingData,
    dataKey,
    numberLabelsOn,
    isOpen,
    setMainSectionsOpen,
    detailType,
    fullWidth,
    noTotal,
    titleToolTip,
    engagementType,
    isLoading,
    createdAtType,
    showAsPercentOfTotal,
    weekly,
    dateType,
    setDateType,
  } = props;
  const classes = useStyles();

  const toggleExpandCollapse = () => {
    if (!setMainSectionsOpen) return;
    setMainSectionsOpen((existingMainSectionsOpen: any) => ({
      ...existingMainSectionsOpen,
      [detailType]: !isOpen,
    }));
  };

  const [totalValue, setTotalValue] = React.useState();
  const [signupValue, setSignupValue] = React.useState();
  const [trialStartsPercent, setTrialStartsPercent] = React.useState<any>();

  const [trialCancels, setTrialCancels] = React.useState<any>();
  const [trialCancelsPercent, setTrialCancelsPercent] = React.useState<any>();
  const [failedPayments, setFailedPayments] = React.useState<any>();
  const [failedPaymentsPercent, setFailedPaymentsPercent] =
    React.useState<any>();
  const [convertedToPaid, setConvertedToPaid] = React.useState<any>();
  const [convertedToPaidPercent, setConvertedToPaidPercent] =
    React.useState<any>();

  const [refunded, setRefunded] = React.useState<any>();
  const [refundedPercent, setRefundedPercent] = React.useState<any>();

  const [stillPending, setStillPending] = React.useState<any>();
  const [stillPendingPercent, setStillPendingPercent] = React.useState<any>();
  const [averageValue, setAverageValue] = React.useState<any>();

  const [startIndex, setStartIndex] = React.useState<any>();
  const [endIndex, setEndIndex] = React.useState<any>();

  const [barType, updateBarType] = React.useState('trialStartsNum');

  const isTrialStartsTable = dataKey === 'trialStartsNum';

  const data = React.useMemo(() => {
    if (isTrialStartsTable) {
      return incomingData.map((existingDataForDate: any) => ({
        ...existingDataForDate,
        trialCancelledFromTrialStartNum:
          existingDataForDate?.trialStartsData?.filter(
            (i: any) =>
              (i.period_type === 'trial' &&
                i.unsubscribe_detected_at &&
                !i.billing_issues_detected_at) ||
              (i.store === 'no-paywall' &&
                moment(i.original_purchase_date)
                  .add(7, 'days')
                  .isSameOrBefore(moment())),
          )?.length || 0,
        trialFailedPaymentNum:
          existingDataForDate?.trialStartsData?.filter(
            (i: any) =>
              i.period_type === 'trial' && i.billing_issues_detected_at,
          )?.length || 0,
        trialConvertedToPaidNum:
          existingDataForDate?.trialStartsData?.filter(
            (i: any) => i.period_type === 'normal' && !i.refunded_at,
          )?.length || 0,
        trialRefundedNum:
          existingDataForDate?.trialStartsData?.filter(
            (i: any) => i.period_type === 'normal' && i.refunded_at,
          )?.length || 0,
        trialPendingNum:
          existingDataForDate?.trialStartsData?.filter(
            (i: any) =>
              i.period_type === 'trial' &&
              !i.billing_issues_detected_at &&
              !i.unsubscribe_detected_at,
          )?.length || 0,
      }));
    }

    return incomingData;
  }, [incomingData]);

  const updateVisibleIndexes = React.useCallback((visibleIndexData: any) => {
    const { startIndex: newStartIndex, endIndex: newEndIndex } =
      visibleIndexData;
    setStartIndex(newStartIndex);
    setEndIndex(newEndIndex);
  }, []);

  React.useEffect(() => {
    // reset the brush start & end index when data changes due to data / referral soure filters
    setStartIndex(undefined);
    setEndIndex(undefined);
  }, [data, dataKey]);

  React.useEffect(() => {
    const newTotalValue = data
      .map((item: any, index: number) => {
        if (
          (!startIndex && !endIndex) ||
          (index >= startIndex && index <= endIndex)
        ) {
          return item?.[dataKey];
        }
        return 0;
      })
      .reduce((a: any, b: any) => a + b, 0);

    setTotalValue(newTotalValue);

    const totalSignedUpValue = data
      .map((item: any, index: number) => {
        if (
          (!startIndex && !endIndex) ||
          (index >= startIndex && index <= endIndex)
        ) {
          return item?.signupsNum;
        }
        return 0;
      })
      .reduce((a: any, b: any) => a + b, 0);
    setSignupValue(totalSignedUpValue);

    if (totalSignedUpValue > 0) {
      const newTrialStartPercent =
        Math.round((newTotalValue / totalSignedUpValue) * 10000) / 100;
      setTrialStartsPercent(newTrialStartPercent);
    }

    if (dataKey === 'activeOnDayNum') {
      const numeratorValue = data
        .map((item: any) => item[dataKey])
        .reduce((a: any, b: any) => a + b, 0);
      const demoninatorValue = data.length;
      const preAverageValue =
        Math.round((numeratorValue / demoninatorValue) * 100) / 100;
      const newAverageValue = Number.isNaN(preAverageValue)
        ? 'n/a'
        : preAverageValue;
      setAverageValue(newAverageValue);
    }

    if (dataKey === 'activeDuringRollingWeekNum') {
      const numeratorValue = data
        .filter((item: any, index: number) => (index + 1) % 7 === 0)
        .map((item: any) => item[dataKey])
        .reduce((a: any, b: any) => a + b, 0);
      const demoninatorValue = data.filter(
        (item: any, index: number) => (index + 1) % 7 === 0,
      ).length;
      const preAverageValue =
        Math.round((numeratorValue / demoninatorValue) * 100) / 100;
      const newAverageValue = Number.isNaN(preAverageValue)
        ? 'n/a'
        : preAverageValue;
      setAverageValue(newAverageValue);
    }

    if (isTrialStartsTable) {
      const newTrialCancels = data
        .map((item: any, index: number) => {
          if (
            (!startIndex && !endIndex) ||
            (index >= startIndex && index <= endIndex)
          ) {
            return item?.trialCancelledFromTrialStartNum;
          }

          return 0;
        })
        .reduce((a: any, b: any) => a + b, 0);

      const newTrialCancelsPercent =
        Math.round((newTrialCancels / newTotalValue) * 10000) / 100;

      const newFailedPayments = data
        .map((item: any, index: number) => {
          if (
            (!startIndex && !endIndex) ||
            (index >= startIndex && index <= endIndex)
          ) {
            return item?.trialFailedPaymentNum;
          }

          return 0;
        })
        .reduce((a: any, b: any) => a + b, 0);

      const newFailedPaymentsPercent =
        Math.round((newFailedPayments / newTotalValue) * 10000) / 100;

      const newConvertedToPaid = data
        .map((item: any, index: number) => {
          if (
            (!startIndex && !endIndex) ||
            (index >= startIndex && index <= endIndex)
          ) {
            return item?.trialConvertedToPaidNum;
          }
          return 0;
        })
        .reduce((a: any, b: any) => a + b, 0);

      const newConvertedToPaidPercent =
        Math.round((newConvertedToPaid / newTotalValue) * 10000) / 100;

      const newRefunded = data
        .map((item: any, index: number) => {
          if (
            (!startIndex && !endIndex) ||
            (index >= startIndex && index <= endIndex)
          ) {
            return item?.trialRefundedNum;
          }
          return 0;
        })
        .reduce((a: any, b: any) => a + b, 0);

      const newRefundedPercent =
        Math.round((newRefunded / newTotalValue) * 10000) / 100;

      const newStillPending = data
        .map((item: any, index: number) => {
          if (
            (!startIndex && !endIndex) ||
            (index >= startIndex && index <= endIndex)
          ) {
            return item?.trialPendingNum;
          }
          return 0;
        })
        .reduce((a: any, b: any) => a + b, 0);

      const newStillPendingPercent =
        Math.round((newStillPending / newTotalValue) * 10000) / 100;

      setTrialCancels(newTrialCancels);
      setTrialCancelsPercent(newTrialCancelsPercent);
      setFailedPayments(newFailedPayments);
      setFailedPaymentsPercent(newFailedPaymentsPercent);
      setConvertedToPaid(newConvertedToPaid);
      setConvertedToPaidPercent(newConvertedToPaidPercent);
      setRefunded(newRefunded);
      setRefundedPercent(newRefundedPercent);
      setStillPending(newStillPending);
      setStillPendingPercent(newStillPendingPercent);
    }
  }, [data, dataKey, startIndex, endIndex]);

  const dataKeys = React.useMemo(() => {
    if (engagementType && createdAtType !== CREATED_AT_TYPES.BOTH) {
      return [
        [CREATED_AT_TYPES.SAME_PERIOD, CREATED_AT_TYPES.STACKED].includes(
          createdAtType,
        )
          ? !showAsPercentOfTotal
            ? `${detailType}CreatedSamePeriodNum`
            : `${detailType}CreatedSamePeriodPercentOfTotal`
          : '',
        [CREATED_AT_TYPES.NOT_SAME_PERIOD, CREATED_AT_TYPES.STACKED].includes(
          createdAtType,
        )
          ? !showAsPercentOfTotal
            ? `${detailType}CreatedNotSamePeriodNum`
            : `${detailType}CreatedNotSamePeriodPercentOfTotal`
          : '',
      ];
    }

    if (showAsPercentOfTotal) {
      return [`${detailType}PercentOfTotal`];
    }

    if (isTrialStartsTable) {
      return [barType];
    }

    return [dataKey];
  }, [
    barType,
    engagementType,
    createdAtType,
    detailType,
    dataKey,
    showAsPercentOfTotal,
  ]);

  if (!isOpen) {
    return (
      <StyledCard fullWidth={fullWidth}>
        <TitleParent onClick={toggleExpandCollapse}>
          <CardHeader title={title} />
          <ExpandMore />
        </TitleParent>
      </StyledCard>
    );
  }

  return (
    <StyledCard fullWidth={fullWidth}>
      <TitleParent onClick={toggleExpandCollapse}>
        <CardHeader title={title} subheader={subtitle} />
        {setMainSectionsOpen && <ExpandLess />}
        {isLoading && <CircularProgress style={{ marginLeft: 10 }} size={20} />}
      </TitleParent>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {isTrialStartsTable && (
          <div style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}>
            <TextField
              id="barType"
              label="Bar Type"
              select
              value={barType}
              SelectProps={{
                native: true,
              }}
              onChange={(event) => updateBarType(event.target.value)}
              style={{ marginRight: 10, minWidth: 100 }}
            >
              {BAR_TYPE_LIST.filter((i) =>
                dateType === 'original_purchase_date'
                  ? i.id !== 'signupsNum'
                  : true,
              ).map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </TextField>
          </div>
        )}
        {isTrialStartsTable && (
          <div style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}>
            <TextField
              id="barType"
              label="Date Type"
              select
              value={dateType}
              SelectProps={{
                native: true,
              }}
              onChange={(event) => {
                setDateType(event.target.value);
                if (
                  event.target.value === 'original_purchase_date' &&
                  barType === 'signupsNum'
                ) {
                  updateBarType('trialStartsNum');
                }
              }}
              style={{ marginRight: 10, minWidth: 100 }}
            >
              {DATE_TYPE_LIST.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </TextField>
          </div>
        )}
      </div>
      {!noTotal &&
        (titleToolTip ? (
          <MaterialHTMLTooltip
            title={titleToolTip}
            placement="bottom-start"
            arrow
          >
            <TotalParent style={{ cursor: 'pointer' }}>
              Total: {totalValue}
            </TotalParent>
          </MaterialHTMLTooltip>
        ) : isTrialStartsTable ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {dateType === 'signup_date' && (
              <TotalParent>Sign Ups: {signupValue}</TotalParent>
            )}
            <TotalParent>
              Started: {totalValue}
              {dateType === 'signup_date' &&
                trialStartsPercent !== undefined &&
                ` (${trialStartsPercent}%)`}
            </TotalParent>
            <TotalParent>
              Canceled: {trialCancels} ({trialCancelsPercent}%)
            </TotalParent>
            <TotalParent>
              Converted: {convertedToPaid} ({convertedToPaidPercent}%)
            </TotalParent>
            <TotalParent>
              Refunded: {refunded} ({refundedPercent}%)
            </TotalParent>
            <TotalParent>
              Failed: {failedPayments} ({failedPaymentsPercent}%)
            </TotalParent>
            <TotalParent>
              Pending: {stillPending} ({stillPendingPercent}%)
            </TotalParent>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {dataKey !== 'activeDuringRollingWeekNum' && (
              <TotalParent>Total: {totalValue}</TotalParent>
            )}
            {dataKey === 'activeOnDayNum' && (
              <TotalParent>Avg: {averageValue}</TotalParent>
            )}
            {dataKey === 'activeDuringRollingWeekNum' && (
              <TotalParent>
                Average of end of each 7th day values: {averageValue}
              </TotalParent>
            )}
          </div>
        ))}
      <CardContent className={classes.cardContent}>
        <div style={{ width: '100%', height: 200 }}>
          <ResponsiveContainer>
            <BarChart data={data}>
              <defs>
                <linearGradient id="colorOne" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#F5307A" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#F99149" stopOpacity={0.4} />
                </linearGradient>
                <linearGradient id="colorToday" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#6DD400" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#00A846" stopOpacity={0.4} />
                </linearGradient>
                <linearGradient id="colorTwo" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#4959f9" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#30ddf5" stopOpacity={0.4} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                name="Date"
                tickFormatter={(date) => {
                  if (date && weekly) {
                    return `Wk${date?.substring(4, 6)};${date?.substring(
                      0,
                      4,
                    )}`;
                  }
                  return moment(date).format('MMM D');
                }}
              />
              <YAxis />
              <Tooltip
                wrapperStyle={{ zIndex: 1 }}
                content={
                  <DashboardToolTip
                    weekly={weekly}
                    createdAtType={createdAtType}
                    engagementType={engagementType}
                    showAsPercentOfTotal={showAsPercentOfTotal}
                    detailType={detailType}
                  />
                }
              />
              {/* <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
                formatter={(value: any, name: any, tooltipProps: any) => {
                  const { payload } = tooltipProps;
                  if (isTrialStartsTable) {
                    const { trialStartsData } = payload;
                    const cancelledTrialsInThisSet = trialStartsData.filter(
                      (i: any) =>
                        (i.period_type === 'trial' &&
                          i.unsubscribe_detected_at) ||
                        (i.store === 'no-paywall' &&
                          moment(i.original_purchase_date)
                            .add(7, 'days')
                            .isSameOrBefore(moment())),
                    )?.length;
                    const newFailedPayments = trialStartsData.filter(
                      (i: any) =>
                        i.period_type === 'trial' &&
                        i.billing_issues_detected_at,
                    )?.length;
                    const convertedTrialsInThisSet = trialStartsData.filter(
                      (i: any) => i.period_type === 'normal',
                    )?.length;
                    const percentConverted = value
                      ? 100 -
                        Math.round(
                          ((cancelledTrialsInThisSet + newFailedPayments) /
                            value) *
                            100,
                        )
                      : 0;
                    return [
                      `${value} \n${cancelledTrialsInThisSet} \n${convertedTrialsInThisSet} \n${newFailedPayments} \n${percentConverted}%`,
                      `Start \nCancel \nConv \nFailed \n✅%`,
                    ];
                  }
                  if (
                    engagementType &&
                    createdAtType !== CREATED_AT_TYPES.BOTH
                  ) {
                    return [
                      name?.includes('CreatedSamePeriod')
                        ? createdAtType === CREATED_AT_TYPES.STACKED
                          ? `${payload[dataKey]} \n${value}`
                          : `${value}`
                        : value,
                      name?.includes('CreatedSamePeriod')
                        ? createdAtType === CREATED_AT_TYPES.STACKED
                          ? `Total \nCreated Same Period `
                          : `Created Same Period `
                        : `Not Created Same Period `,
                    ];
                  }
                  return [
                    `${
                      showAsPercentOfTotal
                        ? `${value}% \n${payload[`${detailType}Num`]}`
                        : value
                    }${
                      engagementType || weekly ? ` \n${payload.totalUsers}` : ``
                    }`,
                    `${showAsPercentOfTotal ? `Percent: \nNumber` : `Number`}:${
                      engagementType || weekly ? ` \nTotal: ` : ``
                    }`,
                  ];
                }}
                separator=""
                labelFormatter={(label: any) => {
                  if (label && weekly) {
                    const year = label.substring(0, 4);
                    const week = label.substring(4, 6);
                    const weekMoment = moment().set({
                      year,
                      week,
                    });
                    return `Wk${week};${year} (${weekMoment
                      .startOf('week')
                      .format('M/D')}-${weekMoment
                      .endOf('week')
                      .format('M/D')})`;
                  }
                  return moment(label).format('MMM D, YYYY');
                }}
              /> */}
              {!engagementType && (
                <Brush
                  dataKey="date"
                  height={30}
                  stroke="#F5307A"
                  onChange={updateVisibleIndexes}
                />
              )}
              {dataKeys.map((dk: any, di: any) => (
                <Bar dataKey={dk} strokeWidth={2} stackId="a">
                  {numberLabelsOn && (
                    <LabelList
                      // @ts-ignore just want some god dam normal labels and a god dam colorful bar is that so much to ask typescript is it?
                      fill={undefined}
                      stroke={undefined}
                      dataKey={dk}
                      formatter={(value) =>
                        showAsPercentOfTotal ? `${value}%` : value
                      }
                      position={
                        dk === 'quickRatio' &&
                        data.filter((i: any) => i.quickRatio < 0).length
                          ? 'bottom'
                          : 'top'
                      }
                    />
                  )}
                  {data.map((entry: any, index: number) => {
                    const isDayWhenTrialsStartThatConvertToday =
                      moment().subtract(7, 'd').format('YYYY-MM-DD') ===
                      entry.date;
                    return (
                      <Cell
                        /* eslint-disable react/no-array-index-key */
                        key={`cell-${index}`}
                        stroke={
                          engagementType
                            ? !di
                              ? '#F5307A'
                              : '#4959f9'
                            : dk === 'trialStartsNum' &&
                                isDayWhenTrialsStartThatConvertToday
                              ? '#6DD400'
                              : '#F5307A'
                        }
                        fill={
                          engagementType
                            ? !di
                              ? 'url(#colorOne)'
                              : 'url(#colorTwo)'
                            : dk === 'trialStartsNum' &&
                                isDayWhenTrialsStartThatConvertToday
                              ? 'url(#colorToday)'
                              : 'url(#colorOne)'
                        }
                      />
                    );
                  })}
                </Bar>
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </StyledCard>
  );
});

export default DashboardChart;
