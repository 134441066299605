// @ts-nocheck
import { ApolloLink, HttpLink } from '@apollo/client';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { getMainDefinition } from 'apollo-utilities';
import _ from 'lodash';
import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';
// import moment from 'moment';
import buildGraphQLProvider, {
  buildQuery as buildRaDataGraphqlSimpleQuery,
} from 'ra-data-graphql-simple';

import api, { apiURL, apiURLForReadOnly } from './api';
import graphQLSchema from './schema.json';

const hasFile = (data: any) => {
  const dataKeys = Object.keys(data);
  const containsFile = dataKeys
    .map((dk) => {
      if (data[dk]?.rawFile) {
        return dk;
      }
      return null;
    })
    .filter((i) => i);
  return containsFile;
};

const client = (url: string, options: Record<string, any> = {}) => {
  if (!options.headers) {
    options.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }
  const token = localStorage.getItem('token') || '';
  options.headers.Authorization = `Bearer ${token}`;
  const { method, body, ...restOptions } = options;
  return new Promise((resolve, reject): any =>
    api
      .request({
        url,
        method,
        body,
        options: restOptions,
        isReadOnly: !method || method === 'GET',
      })
      .then((result) => resolve(result))
      .catch((err) => reject(err)),
  );
};

const buildCustomRESTDataProvider = (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  countHeader = 'Content-Range',
): any => ({
  getList: (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    const options = {};

    return httpClient(url, options).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get(countHeader.toLowerCase()), 10),
    }));
  },

  getOne: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const options = {};

    return httpClient(url, options).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get(countHeader.toLowerCase()), 10),
    }));
  },

  update: (resource: any, params: any) => {
    const files = hasFile(params?.data);
    if (!files.length) {
      const bodyData =
        resource === 'users'
          ? {
              ...params.data,
              VideoProgress: undefined,
              PracticeProgress: undefined,
            }
          : params.data;

      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify(bodyData),
      }).then(({ json }) => ({ data: json }));
    }

    const formData = new FormData();
    const keys = Object.keys(params?.data);
    keys.forEach((key) => {
      if (files.includes(key)) {
        formData.append(key, params.data[key].rawFile);
      } else {
        formData.append(key, params.data[key]);
      }
    });

    return httpClient(`${apiUrl}/${resource}/${params?.id}`, {
      method: 'PUT',
      body: formData,
      headers: {
        Accept: 'application/json',
        // 'Content-Type': 'multipart/form-data',
      },
    }).then(({ json }) => ({ data: json }));
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource: any, params: any) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }),
      ),
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource: any, params: any) => {
    const files = hasFile(params?.data);
    if (!files.length) {
      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    }

    const formData = new FormData();
    const keys = Object.keys(params?.data);
    keys.forEach((key) => {
      if (files.includes(key)) {
        formData.append(key, params.data[key].rawFile);
      } else {
        formData.append(key, params.data[key]);
      }
    });

    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json',
        // 'Content-Type': 'multipart/form-data',
      },
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'text/plain',
      },
    }).then(({ json }) => ({ data: json })),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource: any, params: any) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'text/plain',
          },
        }),
      ),
    ).then((responses) => ({
      data: responses.map(({ json }) => json.id),
    })),
});

const customRESTDataProvider = buildCustomRESTDataProvider(
  `/api/v1` || '',
  client,
  'X-Total-Count',
);

const buildQuery = (introspection) => (fetchType, resource, params) => {
  const builtQuery = buildRaDataGraphqlSimpleQuery(introspection)(
    fetchType,
    resource,
    params,
  );

  const query = {
    ...builtQuery.query,
    definitions: builtQuery.query.definitions.map((definition) => ({
      ...definition,
      selectionSet: {
        ...definition.selectionSet,
        selections: definition.selectionSet.selections.map((selection) => ({
          ...selection,
          selectionSet: {
            ...selection.selectionSet,
            selections: selection.selectionSet.selections.filter(
              (selection) => !selection.selectionSet,
            ),
          },
        })),
      },
    })),
  };

  return {
    ...builtQuery,
    query,
  };
};

const writeHttpLink = new HttpLink({
  uri: `${apiURL}/api/v2/admin/graphql`,
});

const readHttpLink = new HttpLink({
  uri: `${apiURLForReadOnly}/api/v2/admin/graphql`,
});

const isReadOperation = ({ query }) => {
  const { kind, operation } = getMainDefinition(query);
  return (
    kind === 'OperationDefinition' &&
    (operation === 'query' || operation === 'subscription')
  );
};

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization header
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage.getItem('token') || null}`,
    },
  }));

  return forward(operation);
});

const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: 'tokens',
  isTokenValidOrUndefined: async () => !api.isTokenExpired(),
  fetchAccessToken: () => api.refreshTokenFromServer(),
  handleFetch: (refreshTokenJSON) => {
    api.setUserToken(
      refreshTokenJSON.accessToken,
      refreshTokenJSON.refreshToken,
    );
  },
  handleError: (err) => {
    // When the browser is offline and an error occurs we don’t want the
    // user to be logged out.
    //
    // We also don’t want to delete a JWT token from the `localStorage`.
    if (
      navigator.onLine &&
      !(err instanceof TypeError && err.message === 'Network request failed')
    ) {
      api.logOut();
    }
  },
});

const graphQLDataProvider = buildGraphQLProvider({
  introspection: {
    schema: graphQLSchema.__schema,
    operationNames: {
      GET_LIST: (resource) => `raAll${resource.name}s`,
      GET_ONE: (resource) => `ra${resource.name}`,
      GET_MANY: (resource) => `raAll${resource.name}s`,
      GET_MANY_REFERENCE: (resource) => `raAll${resource.name}s`,
      CREATE: (resource) => `raCreate${resource.name}`,
      UPDATE: (resource) => `raUpdate${resource.name}`,
      DELETE: (resource) => `raDelete${resource.name}`,
    },
  },
  clientOptions: {
    link: ApolloLink.from([
      tokenRefreshLink,
      authMiddleware,
      ApolloLink.split(isReadOperation, readHttpLink, writeHttpLink),
    ]),
  },
  buildQuery,
});

const customDataProvider = new Proxy(customRESTDataProvider, {
  get: (target, name) => {
    if (name === 'toJSON') {
      return;
    }

    // eslint-disable-next-line consistent-return
    return async (resource, params) => {
      if (typeof name === 'symbol' || name === 'then') {
        return;
      }

      if (resource.startsWith('g/')) {
        // eslint-disable-next-line consistent-return
        return (await graphQLDataProvider)[name](
          _.upperFirst(_.camelCase(resource.substring(2)))
            .replace(/(s|es)$/, '')
            .replace('_', '-'),
          params,
        );
      }

      // eslint-disable-next-line consistent-return
      return customRESTDataProvider[name](resource, params);
    };
  },
});

export default customDataProvider;
