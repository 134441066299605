/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Avatar,
  ChatContainer,
  MainContainer,
  Message,
  MessageGroup,
  MessageList,
  MessageSeparator,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { Box, CircularProgress } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { GridColDef } from '@material-ui/data-grid';
import { DataGrid } from '@material-ui/data-grid';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import moment from 'moment';
import * as React from 'react';
import { useShowController } from 'react-admin';

import api from '../api';
import kai from '../assets/portrait_of_a_female_white_culturally_ambiguous_mid_30s_w_a048f9eb-8aa9-4885-90b6-c187505ec817-PhotoRoom.png';

type WordResult = {
  start: number;
  end: number;
  phone_score_list: any[];
  hardened: boolean;
  probability: number;
  score: number;
  segment_number: number;
  syllable_score_list: any[];
  text: string;
};
// extra meta data
// s3Filename
// duration
// selfCorrectedCount
// editedCount

type ChatMessage = {
  audio: string;
  content: string | WordResult[];
  exampleAudio: string | null;
  initial: boolean;
  role: 'user' | 'assistant' | 'terminal';
  userFocused: boolean;
  metaData: any;
};

type Goal = {
  id: string;
  conversationId?: string;
  goalTemplateId?: string;
  title: string;
  subtitle: string;
  iconFamily: string;
  icon: string;
  type: string;
  metric: string;
  metricLength?: number;
  target: number;
  targetProgress?: number; // some int between 0 and target
  phonemeId?: string;
  completed: boolean;
  completedOn: string;
  starEligible: boolean;
  isGPTCreated: boolean;
  createdAt: string;
  updatedAt: string;
};
export const SCALE_COLORS = ['#D45530', '#FF9466', '#FEB95A', '#00A15C'];
export const SCALE_VALUES = [0, 60, 80, 90, 100];

export const getScaleColor = (value: number) => {
  if (value < SCALE_VALUES[1]) return SCALE_COLORS[0];
  if (value < SCALE_VALUES[2]) return SCALE_COLORS[1];
  if (value < SCALE_VALUES[3]) return SCALE_COLORS[2];
  if (value >= SCALE_VALUES[3]) return SCALE_COLORS[3];
  return 'black';
};

const goalColumns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    editable: false,
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    editable: false,
  },
  {
    field: 'metric',
    headerName: 'Metric',
    flex: 1,
    editable: false,
  },
  {
    field: 'target',
    headerName: 'Target',
    flex: 1,
    editable: false,
  },
  {
    field: 'targetProgress',
    headerName: 'Current count',
    flex: 1,
    editable: false,
  },
  {
    field: 'completed',
    headerName: 'Completed',
    flex: 1,
    editable: false,
  },
];

const GenConvShow = (props: any) => {
  const { record } = useShowController(props);
  const [isPlaying, setIsPlaying] = React.useState({});
  const audioRef = React.useRef<HTMLAudioElement | null>(null);

  if (!record) return null;

  const playAudio = async (s3Filename: string) => {
    const signedUrlResponse = await api.getCloudFrontSignedUrl(
      s3Filename,
      'useraudio/conversational_segments',
      null,
    );
    const { signedUrl } = signedUrlResponse.json;

    const audio = new Audio(signedUrl);
    setIsPlaying({
      ...isPlaying,
      [s3Filename]: true,
    });
    audioRef.current = audio;
    audio.play();
    audio.onended = () => {
      setIsPlaying({
        ...isPlaying,
        [s3Filename]: false,
      });
      audio.remove();
    };
  };

  const pauseAudio = (s3Filename: string) => {
    if (!audioRef.current) return;
    setIsPlaying({
      ...isPlaying,
      [s3Filename]: false,
    });
    audioRef.current?.pause();
    audioRef.current?.remove();
    audioRef.current = null;
  };

  const hasGoals = record.goals.length > 0;

  return (
    <Card
      style={{
        border: 'none',
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          {record.userTextFeedback ? (
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                User Feedback: {record.userTextFeedback}
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <MainContainer
              responsive
              style={{
                borderRadius: '1rem',
              }}
            >
              <ChatContainer
                style={{
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                }}
              >
                <MessageList>
                  <MessageSeparator>
                    {moment(record.createdAt).format('MMMM Do YYYY @ h:mm a')}
                  </MessageSeparator>
                  {record.chatMessageHistory.map((message: ChatMessage) => {
                    const averageScore = Array.isArray(message.content)
                      ? Math.round(
                          (message.content.reduce(
                            (sum: number, elem: WordResult) =>
                              sum + (elem?.score || 0),
                            0,
                          ) / message.content.length || 1) * 10,
                        ) / 10
                      : undefined;

                    switch (message.role) {
                      case 'user':
                        return (
                          <MessageGroup.Messages style={{ marginTop: 6 }}>
                            <Message
                              model={{
                                type: 'custom',
                                sender: record.User.firstName,
                                direction: 'outgoing',
                                position: 'normal',
                              }}
                            >
                              <Message.CustomContent>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                  }}
                                >
                                  {Array.isArray(message.content)
                                    ? message.content.map(
                                        (curr: WordResult) => (
                                          <div>
                                            <div
                                              style={{
                                                color:
                                                  curr?.score !== undefined
                                                    ? getScaleColor(curr.score)
                                                    : 'black',
                                              }}
                                            >
                                              {curr.text}&nbsp;
                                            </div>
                                          </div>
                                        ),
                                      )
                                    : message.content}
                                </div>
                                {message.metaData.s3Filename ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'flex-end',
                                      position: 'relative',
                                    }}
                                    onClick={() =>
                                      !(isPlaying as any)?.[
                                        message.metaData.s3Filename
                                      ]
                                        ? playAudio(message.metaData.s3Filename)
                                        : pauseAudio(
                                            message.metaData.s3Filename,
                                          )
                                    }
                                  >
                                    {(isPlaying as any)[
                                      message.metaData.s3Filename
                                    ] ? (
                                      <CircularProgress
                                        size={26}
                                        style={{
                                          color: 'green',
                                          position: 'absolute',
                                          top: 4,
                                          right: 4,
                                          zIndex: 1,
                                        }}
                                      />
                                    ) : null}
                                    <VolumeUpIcon
                                      style={{
                                        fontSize: 24,
                                        padding: 5,
                                        cursor: 'pointer',
                                        zIndex: 2,
                                      }}
                                    />
                                  </div>
                                ) : null}

                                {averageScore !== undefined ? (
                                  <div>
                                    <span>Avg Score </span>
                                    <span
                                      style={{
                                        color: getScaleColor(averageScore),
                                      }}
                                    >
                                      {averageScore}%
                                    </span>
                                  </div>
                                ) : null}
                              </Message.CustomContent>
                              <Avatar
                                src={`https://api.dicebear.com/6.x/initials/svg?seed=${record.User.firstName}`}
                                name={record.User.firstName}
                              />
                            </Message>
                            {message.metaData.selfCorrectedCount ||
                            message.metaData.editedCount ? (
                              <Message.Footer
                                dir="rtl"
                                sender={`${
                                  message.metaData.selfCorrectedCount
                                    ? `Fix word count: ${message.metaData.selfCorrectedCount}`
                                    : ''
                                } ${
                                  message.metaData.editedCount
                                    ? `${
                                        message.metaData.selfCorrectedCount
                                          ? '|'
                                          : ''
                                      } Edit word count: ${
                                        message.metaData.editedCount
                                      }`
                                    : ''
                                }`}
                              />
                            ) : null}
                          </MessageGroup.Messages>
                        );
                      case 'assistant':
                        return (
                          <Message
                            model={{
                              message: !Array.isArray(message.content)
                                ? message.content
                                : '',

                              sender: 'Kai',
                              direction: 'incoming',
                              position: 'normal',
                            }}
                          >
                            <Avatar src={kai} name="Kai" />
                          </Message>
                        );
                      case 'terminal':
                        return (
                          <Card
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                          >
                            <CardContent
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              Terminal Card (Data generated on client)
                            </CardContent>
                          </Card>
                        );
                      default:
                        return null;
                    }
                  })}
                </MessageList>
              </ChatContainer>
            </MainContainer>
          </Grid>
          <Box width="100%">
            <Box>
              <Typography
                variant="h5"
                style={{
                  marginBottom: 8,
                }}
              >
                Average Score: {record.averageScore}%
              </Typography>
            </Box>
            {hasGoals ? (
              <Box flex flexGrow={1} width="100%">
                <DataGrid
                  components={{ Toolbar: DataGridTitle }}
                  rowHeight={50}
                  autoHeight
                  rows={record.goals as Goal[]}
                  columns={goalColumns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  checkboxSelection
                  disableSelectionOnClick
                />
              </Box>
            ) : null}
          </Box>
        </Grid>
      </CardContent>
    </Card>
  );
};

function DataGridTitle() {
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
      }}
    >
      <Typography variant="h5" style={{ marginTop: 8, marginLeft: 8 }}>
        Goals
      </Typography>
    </Box>
  );
}

export default GenConvShow;
