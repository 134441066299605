import React, { useContext, useEffect } from 'react';
import { InVideoFormContext } from './InVideoFormProvider';
import { TextField } from '@material-ui/core';

const secondsToms = (d: number) => {
  // eslint-disable-next-line no-param-reassign
  d = Number(d);
  const min = parseInt(`${d / 60}`, 10);
  const second = `${d % 60}`.padStart(2, '0');
  return `${min}:${second}`;
};

export default function FromToForm({
  from,
  to,
}: {
  from: number;
  to?: number;
}) {
  const { from: fromContext, setFrom, to: toContext, setTo } = useContext(
    InVideoFormContext,
  );
  useEffect(() => {
    setFrom(from);
  }, [from]);
  useEffect(() => {
    setTo(to);
  }, [to]);

  return (
    <div>
      <TextField
        onChange={(e) => {
          setFrom(e.target.value);
        }}
        style={{ marginRight: 10 }}
        value={fromContext}
        required
        id="iv-from-to-form-from"
        type="number"
        label="From"
        name="from"
        helperText={fromContext ? secondsToms(fromContext) : null}
      />

      <TextField
        onChange={(e) => {
          setTo(e.target.value);
        }}
        value={toContext}
        required
        id="iv-from-to-form-to"
        type="number"
        label="To"
        name="to"
        helperText={toContext ? secondsToms(toContext) : null}
      />
    </div>
  );
}
