/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, Typography, Tooltip, Box } from '@material-ui/core';

import api from '../../api';
import { useRefresh } from 'react-admin';
import CreateEditSummaryPointModal from './videoSummary/CreateEditSummaryPointModal';

export default function VideoRecapEditor({ record }: { record: any }) {
  const refresh = useRefresh();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pointToEdit, setPointToEdit] = useState<any>({});
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { summaryPoints = [], id } = record;

  const sordedPoints = [...summaryPoints].sort(
    (a: any, b: any) => a.order - b.order,
  );

  const editPoint = async (summaryPoint: {
    id: string;
    pointMarkdown: string;
    order: number;
  }) => {
    setPointToEdit(summaryPoint);
    setIsModalOpen(true);
  };

  const deletePoint = async (pointId: string) => {
    const answer = window.confirm(
      "Are you sure you want to delete this? It can't be undone",
    );
    if (answer) {
      try {
        const response = await api.deleteVideoSummaryPoint(id, pointId);
        if (response.status < 300) {
          refresh();
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  return (
    <div style={{ marginTop: 15 }}>
      <Box display="flex" flexDirection="row">
        <Box marginRight={2} flex={1.5}>
          <div
            style={{
              display: 'flex',
            }}
          >
            <Typography variant="h5">Video Summary Points</Typography>
            <Button
              variant="contained"
              onClick={() => {
                setPointToEdit({});
                setIsModalOpen(true);
              }}
              style={{ marginLeft: 4 }}
            >
              Add new summary point
            </Button>
          </div>
          <Typography variant="body1">
            If there are none, the video will go straight to star reward or the
            next lesson
          </Typography>
          <Typography variant="body1">
            If you&apos;d like to add a phoneme, please use phoneme markup. See
            example in the gray box. You must use the transliteration of the
            phoneme in the parentheses.
          </Typography>
        </Box>
        <Box flex={2}>
          <div
            style={{
              padding: 10,
              paddingLeft: 20,
              paddingRight: 15,
              borderRadius: 5,
              backgroundColor: 'lightgray',
            }}
          >
            {`    The [G](phoneme:G) sound is an important one for English`}
          </div>
        </Box>
      </Box>

      <div style={{ marginTop: 15, marginBottom: 10 }}>
        {sordedPoints.map((point: any) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Tooltip title="Order number (aka 1, 2, 3...)">
              <div
                style={{
                  padding: '5px 10px',
                  borderRadius: 5,
                  marginRight: 15,
                  backgroundColor: '#D3D3D3',
                }}
              >
                {point.order}
              </div>
            </Tooltip>
            <pre
              style={{
                fontFamily: 'sans-serif',
                whiteSpace: 'pre-wrap',
              }}
            >
              {point?.pointMarkdown}{' '}
            </pre>
            <Button
              onClick={() => editPoint(point)}
              style={{ marginLeft: 4, marginRight: 4 }}
              variant="text"
            >
              <EditIcon />
            </Button>
            <Button onClick={() => deletePoint(point.id)}>
              <DeleteIcon aria-label="delete" color="error" />
            </Button>
          </div>
        ))}
      </div>
      <CreateEditSummaryPointModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        point={pointToEdit}
        videoId={id}
        totalPoints={summaryPoints.length}
      />
    </div>
  );
}
