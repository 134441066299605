/* eslint-disable  react/prop-types */

import React, { useReducer, createContext } from 'react';
const DataContext = createContext(null);

const initialState = {};

const dataReducer = (state, action) => {
  switch (action.type) {
    default:
      throw new Error('Unexpected action');
  }
};

const DataProvider = (props) => {
  const [store, dispatch] = useReducer(dataReducer, initialState);
  return (
    <DataContext.Provider value={{ store, dispatch }}>
      {props.children}
    </DataContext.Provider>
  );
};

const DataConsumer = DataContext.Consumer;
export { DataContext, DataProvider, DataConsumer };
