import { useEffect, useRef } from 'react';

export default function usePrevious<T>(
  value: T,
  // eslint-disable-next-line no-unused-vars
  filterUpdates: (_value: T) => boolean = (_v) => true,
): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    if (filterUpdates(value)) {
      ref.current = value;
    }
  }, [value, filterUpdates]); // Only re-run if value changes
  return ref.current;
}
