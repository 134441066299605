import React from 'react';
import {
  AutocompleteArrayInput,
  SimpleForm,
  ReferenceArrayInput,
} from 'react-admin';
import api from '../../api';
import { matchSuggestion } from '../../lesson/utils';

export default function PracticeSetMultipleChoiceAutoComplete({
  practiceSet,
  reloadPracticeSet,
}: any) {
  const record = practiceSet.items
    .filter((i: any) => i.contentType === 'MultipleChoice')
    .map((i: any) => i.contentId);

  const handleSubmit = async ({ multipleChoiceId }: any) => {
    try {
      const result = await api.patchPracticeSet({
        id: practiceSet.id,
        multipleChoiceId,
      });
      if (result.status === 200) {
        reloadPracticeSet();
        //   window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ paddingRight: 10, flex: 1 }}>
      <SimpleForm
        record={{
          multipleChoiceId: record,
        }}
        resource="content/practice_set"
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          height: '100%',
          justifyContent: 'flex-end',
        }}
        save={handleSubmit}
      >
        <ReferenceArrayInput
          label={`Multiple choices for set ${practiceSet.name}`}
          source="multipleChoiceId"
          reference="content/multiplechoice"
          fullWidth
          helperText={false}
        >
          <AutocompleteArrayInput
            debounce={750}
            optionValue="id"
            optionText={(option: any) =>
              `${option?.prompt} (id: ${option?.id?.slice(0, 4)})`
            }
            matchSuggestion={(filterValue: string, choice: any) =>
              matchSuggestion(filterValue, choice, 'prompt')
            }
          ></AutocompleteArrayInput>
        </ReferenceArrayInput>
      </SimpleForm>
    </div>
  );
}
