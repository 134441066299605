import * as MUI from '@material-ui/core';
import * as MUIIcons from '@material-ui/icons';
import graphql from 'babel-plugin-relay/macro';
import * as df from 'date-fns';
import { useNotify } from 'ra-core';
import React, { useCallback } from 'react';
import {
  useFragment,
  useLazyLoadQuery,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay';

import AudioLogAccordion from '../components/AudioLogAccordion';
import IPAText from '../components/IPAText';
import { getScaleColor } from '../gen_conv/GenConvShow';

const DLPAssessmentsTaken = ({ userID }: any) => {
  const assessmentData: any = useLazyLoadQuery(
    graphql`
      query DLPAssessmentsTaken2_Query($userID: ID!) {
        assessmentsTakenForUser(userID: $userID) {
          ...DLPAssessmentsTaken2_AssessmentTaken
        }
      }
    `,
    { userID },
  );

  return (
    <MUI.Box
      display="flex"
      alignSelf="flex-start"
      width="100%"
      flexDirection="column"
    >
      {assessmentData.assessmentsTakenForUser.map((assessment: any) => (
        <AssessmentTaken assessment={assessment} />
      ))}
    </MUI.Box>
  );
};

const AssessmentTaken = ({ assessment: assessmentRef }: any) => {
  const [_isPending, startTransition] = React.useTransition();
  const notify = useNotify();
  const assessment = useFragment(
    graphql`
      fragment DLPAssessmentsTaken2_AssessmentTaken on AssessmentTaken {
        id
        createdAt
        completed

        averageScore
        oldScores: score
      }
    `,
    assessmentRef,
  );

  const [
    commitRefreshAssessmentScoreMutation,
    _isRefreshAssessmentScoreMutationInFlight,
  ] = useMutation(graphql`
    mutation DLPAssessmentsTaken2_RefreshAssessmentScore_Mutation(
      $assessmentTakenID: ID!
    ) {
      refreshAssessmentScoreForAssessmentTakenID(
        assessmentTakenID: $assessmentTakenID
      ) {
        ...DLPAssessmentsTaken2_AssessmentTaken
        ...DLPAssessmentsTaken2_AssessmentTakenInner_assessmentTaken
      }
    }
  `);

  const [assessmentTakenInnerQueryRef, loadAssessmentTakenInnerQuery] =
    useQueryLoader(AssessmentTakenInnerQuery);

  const copyAssessmentTakenID = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      navigator.clipboard.writeText(assessment.id);
      notify(`Copied Assessment Taken ID ${assessment.id} to clipboard.`);
    },
    [assessment],
  );

  return (
    <MUI.Accordion
      variant="outlined"
      TransitionProps={{ unmountOnExit: true }}
      onMouseEnter={() =>
        loadAssessmentTakenInnerQuery({ assessmentTakenID: assessment.id })
      }
    >
      <MUI.AccordionSummary expandIcon={<MUIIcons.ExpandMore />}>
        <MUI.Box
          display="flex"
          gridColumnGap={10}
          alignItems="center"
          width="100%"
        >
          <MUI.Box minWidth={20} onClick={copyAssessmentTakenID}>
            {assessment.completed ? (
              <MUIIcons.CheckCircleSharp
                style={{ color: '#5965EB', verticalAlign: 'text-bottom' }}
                fontSize="small"
              />
            ) : (
              <MUIIcons.Cancel
                style={{ color: 'red', verticalAlign: 'text-bottom' }}
                fontSize="small"
              />
            )}
          </MUI.Box>

          <MUI.Box minWidth={170}>
            <MUI.Typography variant="body1" style={{ fontSize: 15 }}>
              {df.format(assessment.createdAt, 'MMM d, yyyy, h:mm a')}
            </MUI.Typography>
          </MUI.Box>

          <div style={{ flexGrow: 1 }} />
          {assessment.completed && (
            <MUI.Box minWidth={45} marginRight={1}>
              <MUI.Typography variant="body2">
                <Score score={assessment.averageScore} />
              </MUI.Typography>
            </MUI.Box>
          )}

          {assessment.completed && (
            <MUI.Box
              minWidth={45}
              marginRight={1}
              style={{ marginTop: -4, opacity: 0.85 }}
            >
              <MUI.Typography variant="body2" style={{ fontSize: 11 }}>
                <Score score={assessment.oldScores?.averageScore / 100} />
              </MUI.Typography>
              <MUI.Typography
                style={{
                  position: 'absolute',
                  fontSize: 9,
                  fontWeight: 500,
                  paddingTop: 1,
                }}
                color="textPrimary"
              >
                Old Score
              </MUI.Typography>
            </MUI.Box>
          )}
          {assessment.completed && (
            <MUI.Box marginRight={1}>
              <MUI.IconButton
                color="inherit"
                size="small"
                style={{ marginTop: -3, marginBottom: -3 }}
                onClick={(e) => {
                  e.stopPropagation();

                  startTransition(() => {
                    commitRefreshAssessmentScoreMutation({
                      variables: { assessmentTakenID: assessment.id },
                    });
                  });
                }}
              >
                <MUIIcons.Redo />
              </MUI.IconButton>
            </MUI.Box>
          )}
        </MUI.Box>
      </MUI.AccordionSummary>
      <MUI.AccordionDetails>
        <MUI.Box
          width="100%"
          display="flex"
          flexDirection="column"
          gridRowGap={20}
        >
          <React.Suspense
            fallback={
              <MUI.CircularProgress style={{ alignSelf: 'center' }} size={30} />
            }
          >
            {assessmentTakenInnerQueryRef && (
              <AssessmentTakenInner
                assessmentTakenInnerQueryRef={assessmentTakenInnerQueryRef}
              />
            )}
          </React.Suspense>
        </MUI.Box>
      </MUI.AccordionDetails>
    </MUI.Accordion>
  );
};

const AssessmentTakenInnerQuery = graphql`
  query DLPAssessmentsTaken2_AssessmentTakenInner_Query(
    $assessmentTakenID: ID!
  ) {
    assessmentTakenByID(id: $assessmentTakenID) {
      ...DLPAssessmentsTaken2_AssessmentTakenInner_assessmentTaken
    }
  }
`;

const AssessmentTakenInner = ({ assessmentTakenInnerQueryRef }: any) => {
  const assessmentTakenData: any = usePreloadedQuery(
    AssessmentTakenInnerQuery,
    assessmentTakenInnerQueryRef,
  );

  const assessment = useFragment(
    graphql`
      fragment DLPAssessmentsTaken2_AssessmentTakenInner_assessmentTaken on AssessmentTaken {
        ...DLPAssessmentsTaken2_Categories
        ...DLPAssessmentsTaken2_BottomSkills
        ...DLPAssessmentsTaken2_TopSkills
        ...DLPAssessmentsTaken2_DetailedResults
      }
    `,
    assessmentTakenData.assessmentTakenByID,
  );

  return (
    <>
      <MUI.Box>
        <MUI.Typography variant="h6" gutterBottom>
          Categories
        </MUI.Typography>
        <Categories assessment={assessment} />
      </MUI.Box>

      <MUI.Box>
        <MUI.Typography variant="h6" gutterBottom>
          Bottom 5 Skills
        </MUI.Typography>
        <BottomSkills assessment={assessment} />
      </MUI.Box>

      <MUI.Box>
        <MUI.Typography variant="h6" gutterBottom>
          Top 3 Skills
        </MUI.Typography>
        <TopSkills assessment={assessment} />
      </MUI.Box>

      <MUI.Divider style={{ marginTop: 10, marginBottom: 10 }} />

      <DetailedResults assessment={assessment} />
    </>
  );
};

const Categories = ({ assessment: assessmentRef }: any) => {
  const assessment = useFragment(
    graphql`
      fragment DLPAssessmentsTaken2_Categories on AssessmentTaken {
        consonants: assessmentBySkillCategory(category: CONSONANTS) {
          averageScore
          skillAssessments {
            id
            ...DLPAssessmentsTaken2_SkillAssessment
          }
        }
        vowels: assessmentBySkillCategory(category: VOWELS) {
          averageScore
          skillAssessments {
            id
            ...DLPAssessmentsTaken2_SkillAssessment
          }
        }
        compoundSounds: assessmentBySkillCategory(category: COMPOUND_SOUNDS) {
          averageScore
          skillAssessments {
            id
            ...DLPAssessmentsTaken2_SkillAssessment
          }
        }
      }
    `,
    assessmentRef,
  );

  return (
    <MUI.Box>
      {[
        [assessment.vowels, 'Vowels'],
        [assessment.consonants, 'Consonants'],
        [assessment.compoundSounds, 'Compound Sounds'],
      ].map(([category, title]) => (
        <MUI.Accordion
          variant="outlined"
          style={{ width: '100%' }}
          key={title}
          TransitionProps={{ unmountOnExit: true }}
        >
          <MUI.AccordionSummary expandIcon={<MUIIcons.ExpandMore />}>
            <MUI.Typography variant="body1">
              <div style={{ minWidth: 160, display: 'inline-block' }}>
                {title}
              </div>{' '}
              <MUI.Typography variant="body2" display="inline">
                <Score score={category?.averageScore} />
              </MUI.Typography>
            </MUI.Typography>
          </MUI.AccordionSummary>
          <MUI.AccordionDetails>
            <MUI.Box display="flex" flexDirection="column" width="100%">
              {category.skillAssessments.map((skillAssessment: any) => (
                <SkillAccordion
                  skillAssessment={skillAssessment}
                  key={skillAssessment.id}
                />
              ))}
            </MUI.Box>
          </MUI.AccordionDetails>
        </MUI.Accordion>
      ))}
    </MUI.Box>
  );
};

const BottomSkills = ({ assessment: assessmentRef }: any) => {
  const skillAssessments = useFragment(
    graphql`
      fragment DLPAssessmentsTaken2_BottomSkills on AssessmentTaken {
        bottomSkillAssessments(limit: 5) {
          id
          ...DLPAssessmentsTaken2_SkillAssessment
        }
      }
    `,
    assessmentRef,
  );

  return (
    <MUI.Box>
      {skillAssessments.bottomSkillAssessments.map((skillAssessment: any) => (
        <SkillAccordion
          skillAssessment={skillAssessment}
          key={skillAssessment.id}
        />
      ))}
    </MUI.Box>
  );
};

const TopSkills = ({ assessment: assessmentRef }: any) => {
  const skillAssessments = useFragment(
    graphql`
      fragment DLPAssessmentsTaken2_TopSkills on AssessmentTaken {
        topSkillAssessments(limit: 3) {
          id
          ...DLPAssessmentsTaken2_SkillAssessment
        }
      }
    `,
    assessmentRef,
  );

  return (
    <MUI.Box>
      {skillAssessments.topSkillAssessments.map((skillAssessment: any) => (
        <SkillAccordion
          skillAssessment={skillAssessment}
          key={skillAssessment.id}
        />
      ))}
    </MUI.Box>
  );
};

const SkillAccordion = ({ skillAssessment: skillAssessmentRef }: any) => {
  const skillAssessment = useFragment(
    graphql`
      fragment DLPAssessmentsTaken2_SkillAssessment on SkillAssessment {
        score

        skill {
          name
        }

        topAudioLogExamples {
          id
          ...DLPAssessmentsTaken2_SkillAssessmentExample
        }

        bottomAudioLogExamples {
          id
          ...DLPAssessmentsTaken2_SkillAssessmentExample
        }
      }
    `,
    skillAssessmentRef,
  );

  return (
    <MUI.Accordion
      variant="outlined"
      style={{ width: '100%' }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <MUI.AccordionSummary expandIcon={<MUIIcons.ExpandMore />}>
        <MUI.Typography variant="body2">
          <span
            style={{
              minWidth: 160,
              display: 'inline-block',
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <span
              style={{
                userSelect: 'text',
                cursor: 'text',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <IPAText text={skillAssessment.skill.name} />
            </span>
          </span>{' '}
          <Score score={skillAssessment.score} />
        </MUI.Typography>
      </MUI.AccordionSummary>
      <MUI.AccordionDetails>
        <MUI.Box display="flex" flexDirection="column" width="100%">
          <MUI.Typography variant="subtitle2" gutterBottom>
            Top Examples
          </MUI.Typography>
          <MUI.Box
            display="flex"
            flexDirection="column"
            gridRowGap={1}
            marginBottom={2}
          >
            {skillAssessment.topAudioLogExamples.map((example: any) => (
              <SkillAssessmentExample example={example} key={example.id} />
            ))}
          </MUI.Box>
          <MUI.Typography variant="subtitle2" gutterBottom>
            Bottom Examples
          </MUI.Typography>
          <MUI.Box display="flex" flexDirection="column" gridRowGap={1}>
            {skillAssessment.bottomAudioLogExamples.map((example: any) => (
              <SkillAssessmentExample example={example} key={example.id} />
            ))}
          </MUI.Box>
        </MUI.Box>
      </MUI.AccordionDetails>
    </MUI.Accordion>
  );
};

export const SkillAssessmentExample = React.memo(
  ({ example: exampleRef, minWordWidth = 120, truncate = false }: any) => {
    const example = useFragment(
      graphql`
        fragment DLPAssessmentsTaken2_SkillAssessmentExample on SkillAssessmentExample {
          speechaceExtents

          audioLog {
            id
            speechaceParsed
          }
        }
      `,
      exampleRef,
    );

    if (!example.audioLog.speechaceParsed) {
      return null;
    }

    return example.speechaceExtents.map(
      (
        [[wordStartIndex, phoneStartIndex], [wordEndIndex, phoneEndIndex]]: any,
        extentIndex,
      ) => {
        const words = example.audioLog.speechaceParsed.word_score_list.slice(
          wordStartIndex,
          wordEndIndex + 1,
        );

        return (
          <MUI.Box display="flex" flexDirection="row" key={extentIndex}>
            <MUI.Box
              minWidth={minWordWidth}
              style={{
                ...(truncate && {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }),
                marginRight: 8,
              }}
            >
              <MUI.Link
                href={`/#/audiologs?displayedFilters=%7B%22id%22%3Atrue%7D&filter=%7B%22id%22%3A%22${example.audioLog.id}%22%7D&order=DESC&page=1&perPage=10&sort=createdAt`}
                target="_blank"
                color="inherit"
                display="inline"
              >
                <MUI.Typography variant="body2" display="inline">
                  {words.map((w) => w.word).join(' ')}
                </MUI.Typography>
              </MUI.Link>
            </MUI.Box>
            <MUI.Box minWidth={100}>
              <MUI.Typography
                variant="body2"
                style={{
                  ...(truncate && {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }),
                }}
              >
                {words.flatMap((w, wordIndex) =>
                  w.phone_score_list.flatMap((p, phoneIndex) => {
                    const phone = applyPhoneStress(p.phone, p.stress_level);

                    const soundMostLike = applyPhoneStress(
                      p.sound_most_like,
                      p.predicted_stress_level,
                    );

                    return (
                      <span key={`${wordIndex}-${phoneIndex}`}>
                        <MUI.Tooltip
                          title={`${Math.round(p.quality_score)}%`}
                          placement="top"
                          arrow
                        >
                          <span
                            style={{
                              color: getScaleColor(p.quality_score),
                              ...(!(
                                (wordIndex === 0 &&
                                  phoneIndex < phoneStartIndex) ||
                                (wordIndex === words.length - 1 &&
                                  phoneIndex > phoneEndIndex)
                              ) && {
                                textDecoration: 'underline',
                                fontWeight: 500,
                              }),
                            }}
                          >
                            {phone}
                            {soundMostLike &&
                              soundMostLike !== phone &&
                              `(${soundMostLike})`}
                          </span>
                        </MUI.Tooltip>{' '}
                      </span>
                    );
                  }),
                )}
              </MUI.Typography>
            </MUI.Box>
          </MUI.Box>
        );
      },
    );
  },
);

function applyPhoneStress(phoneme, stress) {
  return phoneme === 'ah' && stress != null
    ? `${phoneme}${stress === 2 ? 1 : stress}`
    : phoneme;
}

const Score = ({ score }: any) => {
  score = Math.round(score * 100);

  const scaleColor = getScaleColor(score);
  return (
    <MUI.Typography
      variant="inherit"
      style={{
        backgroundColor: scaleColor,
        color: 'white',
        borderRadius: 5,
        display: 'inline',
        padding: '2px 5px',
        fontWeight: 500,
      }}
    >
      {score}%
    </MUI.Typography>
  );
};

const DetailedResults = ({ assessment: assessmentRef }: any) => {
  const assessment = useFragment(
    graphql`
      fragment DLPAssessmentsTaken2_DetailedResults on AssessmentTaken {
        audioLogsLatest {
          score
          speechace
          audioFile
          modelName
        }
      }
    `,
    assessmentRef,
  );

  return (
    <MUI.Box width="100%">
      <MUI.Accordion
        variant="outlined"
        style={{ width: '100%' }}
        TransitionProps={{ unmountOnExit: true }}
      >
        <MUI.AccordionSummary expandIcon={<MUIIcons.ExpandMore />}>
          <MUI.Typography variant="h6">Detailed Results</MUI.Typography>
        </MUI.AccordionSummary>

        <MUI.AccordionDetails>
          <MUI.Box width="100%">
            {assessment.audioLogsLatest.map(
              ({ audioFile, modelName, score, speechace }, index) => (
                <AudioLogAccordion
                  key={index}
                  audioFile={audioFile}
                  modelName={modelName}
                  score={score}
                  results={speechace}
                />
              ),
            )}
          </MUI.Box>
        </MUI.AccordionDetails>
      </MUI.Accordion>
    </MUI.Box>
  );
};

export default DLPAssessmentsTaken;
