// @ts-nocheck

/* eslint-disable  no-plusplus */

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import { CardHeader } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ResponsiveContainer } from 'recharts';

import api from '../../api';
import {
  ReferralChartContainer,
  StyledCard,
  TitleParent,
} from '../charts/Styled';

const SORT_BY_ENUM = {
  // PROVISIONAL_USERS: 'provisionalUsers',
  ALL_USERS: 'allUsers',
  SUBSCRIBERS: 'subscribers',
  ACTIVE_SUBSCRIBERS: 'activeSubscribers',
  SOURCES: 'sources',
  CANCELLED_TRIALS: 'cancelledTrials',
  REFUNDS: 'refunds',
};

export default function ReferralSourceTable({
  startDate,
  endDate,
  phoneOS,
  productDuration,
  timezone,
  country,
  region,
}: any) {
  const [pending, setPending] = useState(false);
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [sortBy, setSortBy] = useState(SORT_BY_ENUM.ALL_USERS);
  const [showOtherData, setShowOtherData] = useState({});

  useEffect(() => {
    const getMainStatsForReferral = async () => {
      setPending(true);
      try {
        const newMainStats: any = await api.getUserReferralSourceStats(
          startDate,
          endDate,
          phoneOS,
          productDuration,
          timezone,
          country,
          region,
        );
        if (newMainStats.status === 200) {
          setData(newMainStats.json);
        } else {
          setData({});
        }
      } catch (error) {
        setData({});
      }
      setPending(false);
    };
    if (isOpen) {
      getMainStatsForReferral();
    }
  }, [
    startDate,
    endDate,
    phoneOS,
    timezone,
    isOpen,
    country,
    region,
    productDuration,
  ]);

  const allKeyTypes: string[] = React.useMemo(() => {
    const returnedData = [];
    Object.keys(data).forEach((key) => {
      const dataForDate = data[key];
      const allKeys = [
        // ...Object.keys(dataForDate.provisionalUsers),
        ...Object.keys(dataForDate.subscribers),
        ...Object.keys(dataForDate.allUsers),
      ];
      for (let i = 0; i < allKeys.length; i++) {
        const element = allKeys[i];
        if (returnedData.indexOf(element) === -1 && element !== 'otherData') {
          returnedData.push(element);
        }
      }
    });
    return returnedData;
  }, [data]);

  const otherDataKeyTypes: string[] = React.useMemo(() => {
    const returnedData = [];
    Object.keys(data).forEach((key) => {
      const dataForDate = data[key];
      const allKeys = [
        // ...Object.keys(dataForDate.provisionalUsers?.otherData || {}),
        ...Object.keys(dataForDate.subscribers?.otherData || {}),
        ...Object.keys(dataForDate.allUsers?.otherData || {}),
      ];
      for (let i = 0; i < allKeys.length; i++) {
        const element = allKeys[i];
        if (returnedData.indexOf(element) === -1) {
          returnedData.push(element);
        }
      }
    });
    return returnedData;
  }, [data]);

  const rows = Object.keys(data)
    ?.map((day) => ({
      day,
      // provisionalUsers: data[day].provisionalUsers,
      // provisionalUsersWhoSignedUp: data[day].provisionalUsersWhoSignedUp,
      activeSubscribers: data[day].activeSubscribers,
      refunds: data[day].refunds,
      subscribers: data[day].subscribers,
      cancelledTrials: data[day].cancelledTrials,
      allUsers: data[day].allUsers,
      accountsCreated: data[day].accountsCreated,
      newSessions: data[day].newSessions,
    }))
    .reverse();

  const sum = (nums) => nums.reduce((a, b) => a + b, 0);

  const totalBorderColor = '#A1A2A6';

  function AverageConv({ numerator = [0], denominator = [0] }) {
    const averageConv = (
      Math.round((sum(numerator) / (sum(denominator) || 1)) * 1000) / 10
    ).toFixed(1);

    return (
      <Tooltip title="Average">
        <div style={{ marginTop: 4, fontWeight: 'bold' }}>{averageConv}%</div>
      </Tooltip>
    );
  }

  const showOtherDataForRowKey = (rowKey) => {
    setShowOtherData((existingValue) => ({
      ...existingValue,
      [rowKey]: !existingValue[rowKey],
    }));
  };

  return (
    <StyledCard fullWidth>
      <TitleParent onClick={() => setIsOpen((o) => !o)}>
        <CardHeader title="Referral source" />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
        {pending && <CircularProgress style={{ marginLeft: 10 }} size={20} />}
      </TitleParent>
      {isOpen && (
        <ReferralChartContainer>
          <ResponsiveContainer>
            <TableContainer>
              <Table style={{ minWidth: 1200 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ cursor: 'pointer' }}
                      onClick={() => setSortBy(SORT_BY_ENUM.SOURCES)}
                    >
                      Week User Joined
                      {sortBy === SORT_BY_ENUM.SOURCES && ` (*)`}
                    </TableCell>
                    {/* <Tooltip title="People who provided a referral source, including those who did not finish signing up">
                      <TableCell
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setSortBy(SORT_BY_ENUM.PROVISIONAL_USERS)
                        }
                        align="right"
                      >
                        Provisional Users
                        {sortBy === SORT_BY_ENUM.PROVISIONAL_USERS && ` (*)`}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title="% of people who provided a source then signed up.">
                      <TableCell align="right">% Signed up</TableCell>
                    </Tooltip> */}
                    <Tooltip title="Users who completed onboarding and provided a referral source.">
                      <TableCell
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSortBy(SORT_BY_ENUM.ALL_USERS)}
                        align="right"
                      >
                        Onboarded Users
                        {sortBy === SORT_BY_ENUM.ALL_USERS && ` (*)`}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title="Users who's sub status IS NOT never_subscribed. Meaning they had an intent to pay at least once.">
                      <TableCell
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSortBy(SORT_BY_ENUM.SUBSCRIBERS)}
                        align="right"
                      >
                        Trials{sortBy === SORT_BY_ENUM.SUBSCRIBERS && ` (*)`}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title="Trials / Onboarded Users">
                      <TableCell align="right">Trial Start %</TableCell>
                    </Tooltip>
                    <Tooltip title="Users who's sub status IS expired_trial or trial_not_renew or failed payment; but not subscribe_not_renew and not expired_paid.">
                      <TableCell
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSortBy(SORT_BY_ENUM.CANCELLED_TRIALS)}
                        align="right"
                      >
                        Cancelled Trials
                        {sortBy === SORT_BY_ENUM.CANCELLED_TRIALS && ` (*)`}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title="Cancelled trials over all trials">
                      <TableCell align="right">Cancelled %</TableCell>
                    </Tooltip>

                    <Tooltip title="Subscription status = subscriber or subscribed_not_renew or expired_paid.">
                      <TableCell
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setSortBy(SORT_BY_ENUM.ACTIVE_SUBSCRIBERS)
                        }
                        align="right"
                      >
                        Subscribers
                        {sortBy === SORT_BY_ENUM.ACTIVE_SUBSCRIBERS && ` (*)`}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title="Subscribers / Trials">
                      <TableCell align="right">Subscribed %</TableCell>
                    </Tooltip>
                    <Tooltip title="Users who's sub status IS refunded.">
                      <TableCell
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSortBy(SORT_BY_ENUM.REFUNDS)}
                        align="right"
                      >
                        Refunds
                        {sortBy === SORT_BY_ENUM.REFUNDS && ` (*)`}
                      </TableCell>
                    </Tooltip>
                    <Tooltip title="Refunds over everyone who converted">
                      <TableCell align="right">Refunded %</TableCell>
                    </Tooltip>

                    <TableCell align="right">First Open (Installs)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    const sortedAllKeyTypes = [...allKeyTypes]
                      .filter((i) => {
                        // const provisionalUsersVal = row?.provisionalUsers?.[i];
                        const allUsersVal = row?.allUsers?.[i];
                        const subscribersVal = row?.subscribers?.[i];
                        const activeSubscribersVal =
                          row?.activeSubscribers?.[i];
                        if (
                          // provisionalUsersVal ||
                          allUsersVal ||
                          subscribersVal ||
                          activeSubscribersVal
                        ) {
                          return true;
                        }
                        return false;
                      })
                      .sort((a, b) => {
                        if (sortBy === SORT_BY_ENUM.SOURCES) {
                          return a > b ? 1 : -1;
                        }
                        if (!row?.[sortBy]?.[a]) return 1;
                        if (!row?.[sortBy]?.[b]) return -1;

                        return row?.[sortBy]?.[a] > row?.[sortBy]?.[b] ? -1 : 1;
                      });

                    const weekStart = moment()
                      .year(Number(row.day?.substring(0, 4)))
                      .week(Number(row.day?.substring(4, 6)))
                      .startOf('week');

                    const weekEnd = moment()
                      .year(Number(row.day?.substring(0, 4)))
                      .week(Number(row.day?.substring(4, 6)))
                      .endOf('week');

                    const isWeekInComplete =
                      weekStart.isBefore(moment(startDate), 'date') ||
                      weekEnd.isAfter(moment(endDate), 'date');

                    return (
                      <>
                        <TableRow key={`${row.day}_header`}>
                          <TableCell
                            scope="row"
                            padding="none"
                            style={{
                              borderBottom: 0,
                              paddingTop: 16,
                              paddingLeft: 16,
                            }}
                          >
                            {`${weekStart.format('MMM Do')} —
                          ${weekEnd.format('MMM Do, YYYY')}`}{' '}
                            {isWeekInComplete ? `(Incomplete)` : ``}
                          </TableCell>
                        </TableRow>
                        {sortedAllKeyTypes.map((keyType) => (
                          <>
                            <TableRow key={`${row.day}_${keyType}`} hover>
                              <TableCell
                                scope="row"
                                padding="none"
                                style={{
                                  borderBottom: 0,
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                }}
                              >
                                {keyType === 'Other' ? (
                                  <div
                                    onClick={() =>
                                      showOtherDataForRowKey(row.day)
                                    }
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <div>
                                      &nbsp;&nbsp;&nbsp;&nbsp; {keyType}{' '}
                                      {showOtherData?.[row.day] ? (
                                        <>⌄</>
                                      ) : (
                                        <>›</>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <>&nbsp;&nbsp;&nbsp;&nbsp; {keyType}</>
                                )}
                              </TableCell>

                              <TableCell
                                align="right"
                                style={{
                                  color: row.allUsers[keyType]
                                    ? 'black'
                                    : 'white',
                                  borderBottom: 0,
                                  paddingRight: 16,
                                }}
                                scope="row"
                                padding="none"
                              >
                                {row.allUsers[keyType] ?? 0}
                              </TableCell>

                              <TableCell
                                align="right"
                                style={{
                                  color: row.subscribers[keyType]
                                    ? 'black'
                                    : 'white',
                                  borderBottom: 0,
                                  paddingRight: 16,
                                }}
                                scope="row"
                                padding="none"
                              >
                                {row.subscribers[keyType] ?? 0}
                              </TableCell>

                              <TableCell
                                align="right"
                                scope="row"
                                padding="none"
                                style={{ borderBottom: 0, paddingRight: 16 }}
                              >
                                {!Number.isNaN(
                                  Number(
                                    row.subscribers[keyType] /
                                      row.allUsers[keyType],
                                  ),
                                )
                                  ? `${(
                                      Math.round(
                                        (row.subscribers[keyType] /
                                          row.allUsers[keyType]) *
                                          1000,
                                      ) / 10
                                    ).toFixed(1)}%`
                                  : '-'}
                              </TableCell>

                              <TableCell
                                align="right"
                                style={{
                                  color: row.cancelledTrials?.[keyType]
                                    ? 'black'
                                    : 'white',
                                  borderBottom: 0,
                                  paddingRight: 16,
                                }}
                                scope="row"
                                padding="none"
                              >
                                {row.cancelledTrials?.[keyType] ?? 0}
                              </TableCell>

                              <TableCell
                                align="right"
                                style={{
                                  borderBottom: 0,
                                  paddingRight: 16,
                                }}
                                scope="row"
                                padding="none"
                              >
                                {!Number.isNaN(
                                  Number(
                                    row.cancelledTrials[keyType] /
                                      row.subscribers[keyType],
                                  ),
                                )
                                  ? `${(
                                      Math.round(
                                        (row.cancelledTrials[keyType] /
                                          row.subscribers[keyType]) *
                                          1000,
                                      ) / 10
                                    ).toFixed(1)}%`
                                  : '-'}
                              </TableCell>

                              <TableCell
                                align="right"
                                style={{
                                  color: row.activeSubscribers[keyType]
                                    ? 'black'
                                    : 'white',
                                  borderBottom: 0,
                                  paddingRight: 16,
                                }}
                                scope="row"
                                padding="none"
                              >
                                {row.activeSubscribers[keyType]}
                              </TableCell>
                              <TableCell
                                align="right"
                                scope="row"
                                padding="none"
                                style={{ borderBottom: 0, paddingRight: 16 }}
                              >
                                {!Number.isNaN(
                                  Number(
                                    row.activeSubscribers[keyType] /
                                      row.subscribers[keyType],
                                  ),
                                )
                                  ? `${(
                                      Math.round(
                                        (row.activeSubscribers[keyType] /
                                          row.subscribers[keyType]) *
                                          1000,
                                      ) / 10
                                    ).toFixed(1)}%`
                                  : '-'}
                              </TableCell>

                              {/*  */}

                              <TableCell
                                align="right"
                                style={{
                                  color: row.refunds[keyType]
                                    ? 'black'
                                    : 'white',
                                  borderBottom: 0,
                                  paddingRight: 16,
                                }}
                                scope="row"
                                padding="none"
                              >
                                {row.refunds[keyType]}
                              </TableCell>
                              <TableCell
                                align="right"
                                scope="row"
                                padding="none"
                                style={{ borderBottom: 0, paddingRight: 16 }}
                              >
                                {!Number.isNaN(
                                  Number(
                                    row.refunds[keyType] /
                                      (row.refunds[keyType] +
                                        row.activeSubscribers[keyType]),
                                  ),
                                )
                                  ? `${(
                                      Math.round(
                                        (row.refunds[keyType] /
                                          (row.refunds[keyType] +
                                            row.activeSubscribers[keyType])) *
                                          1000,
                                      ) / 10
                                    ).toFixed(1)}%`
                                  : '-'}
                              </TableCell>
                              {/*  */}

                              <TableCell
                                scope="row"
                                padding="none"
                                style={{
                                  borderBottom: 0,
                                  paddingLeft: 16,
                                  paddingRight: 16,
                                }}
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;
                              </TableCell>
                            </TableRow>
                            {keyType === 'Other' && showOtherData?.[row.day]
                              ? [...otherDataKeyTypes, 'Not specified']
                                  .filter((i) => {
                                    // const provisionalUsersVal =
                                    //   row?.provisionalUsers?.otherData?.[i];
                                    const allUsersVal =
                                      row?.allUsers?.otherData?.[i];
                                    const subscribersVal =
                                      row?.subscribers?.otherData?.[i];
                                    const activeSubscribersVal =
                                      row?.activeSubscribers?.otherData?.[i];
                                    if (
                                      // provisionalUsersVal ||
                                      allUsersVal ||
                                      subscribersVal ||
                                      activeSubscribersVal ||
                                      i === 'Not specified'
                                    ) {
                                      return true;
                                    }
                                    return false;
                                  })
                                  .sort((a, b) => {
                                    if (sortBy === SORT_BY_ENUM.SOURCES) {
                                      return a > b ? 1 : -1;
                                    }
                                    if (!row?.[sortBy]?.otherData?.[a]) {
                                      return 1;
                                    }

                                    if (!row?.[sortBy]?.otherData?.[b]) {
                                      return -1;
                                    }
                                    return row?.[sortBy]?.otherData?.[a] >
                                      row?.[sortBy]?.otherData?.[b]
                                      ? -1
                                      : 1;
                                  })
                                  .map((otherRowKey, index, array) => (
                                    <TableRow
                                      style={{
                                        borderColor: 'black',
                                        borderLeftWidth: 0,
                                        borderRightWidth: 0,
                                        borderTopWidth: index === 0 ? 1 : 0,
                                        borderBottomWidth:
                                          index === array.length - 1 ? 1 : 0,
                                        borderStyle: 'solid',
                                      }}
                                    >
                                      <TableCell
                                        scope="row"
                                        padding="none"
                                        style={{
                                          borderBottom: 0,
                                          paddingLeft: 16,
                                          paddingRight: 16,
                                        }}
                                      >
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                        {otherRowKey?.length > 27 ? (
                                          <Tooltip title={otherRowKey}>
                                            <span>
                                              {otherRowKey?.slice(0, 25)}...
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          otherRowKey
                                        )}
                                      </TableCell>

                                      <TableCell
                                        align="right"
                                        style={{
                                          color:
                                            otherRowKey === 'Not specified'
                                              ? (row.allUsers?.Other ?? 0) -
                                                Object.values(
                                                  row.allUsers?.otherData || {},
                                                ).reduce((t, a) => t + a, 0)
                                                ? 'black'
                                                : 'white'
                                              : row.allUsers?.otherData?.[
                                                    otherRowKey
                                                  ]
                                                ? 'black'
                                                : 'white',
                                          borderBottom: 0,
                                          paddingRight: 16,
                                        }}
                                        scope="row"
                                        padding="none"
                                      >
                                        {otherRowKey === 'Not specified'
                                          ? (row.allUsers?.Other ?? 0) -
                                            Object.values(
                                              row.allUsers?.otherData || {},
                                            ).reduce((t, a) => t + a, 0)
                                          : row.allUsers?.otherData?.[
                                              otherRowKey
                                            ] ?? 0}
                                      </TableCell>

                                      <TableCell
                                        align="right"
                                        style={{
                                          color:
                                            otherRowKey === 'Not specified'
                                              ? (row.subscribers?.Other ?? 0) -
                                                Object.values(
                                                  row.subscribers?.otherData ||
                                                    {},
                                                ).reduce((t, a) => t + a, 0)
                                                ? 'black'
                                                : 'white'
                                              : row.subscribers?.otherData?.[
                                                    otherRowKey
                                                  ]
                                                ? 'black'
                                                : 'white',
                                          borderBottom: 0,
                                          paddingRight: 16,
                                        }}
                                        scope="row"
                                        padding="none"
                                      >
                                        {otherRowKey === 'Not specified'
                                          ? (row.subscribers?.Other ?? 0) -
                                            Object.values(
                                              row.subscribers?.otherData || {},
                                            ).reduce((t, a) => t + a, 0)
                                          : row.subscribers?.otherData?.[
                                              otherRowKey
                                            ] ?? 0}
                                      </TableCell>

                                      <TableCell
                                        align="right"
                                        scope="row"
                                        padding="none"
                                        style={{
                                          borderBottom: 0,
                                          paddingRight: 16,
                                        }}
                                      >
                                        {otherRowKey === 'Not specified'
                                          ? !Number.isNaN(
                                              ((row.subscribers?.Other ?? 0) -
                                                Object.values(
                                                  row.subscribers?.otherData ||
                                                    {},
                                                ).reduce((t, a) => t + a, 0)) /
                                                ((row.allUsers?.Other ?? 0) -
                                                  Object.values(
                                                    row.allUsers?.otherData ||
                                                      {},
                                                  ).reduce((t, a) => t + a, 0)),
                                            )
                                            ? `${(
                                                Math.round(
                                                  (((row.subscribers?.Other ??
                                                    0) -
                                                    Object.values(
                                                      row.subscribers
                                                        ?.otherData || {},
                                                    ).reduce(
                                                      (t, a) => t + a,
                                                      0,
                                                    )) /
                                                    ((row.allUsers?.Other ??
                                                      0) -
                                                      Object.values(
                                                        row.allUsers
                                                          ?.otherData || {},
                                                      ).reduce(
                                                        (t, a) => t + a,
                                                        0,
                                                      ))) *
                                                    1000,
                                                ) / 10
                                              ).toFixed(1)}%`
                                            : '-'
                                          : !Number.isNaN(
                                                Number(
                                                  row.subscribers?.otherData?.[
                                                    otherRowKey
                                                  ] /
                                                    row.allUsers?.otherData?.[
                                                      otherRowKey
                                                    ],
                                                ),
                                              )
                                            ? `${(
                                                Math.round(
                                                  (row.subscribers?.otherData?.[
                                                    otherRowKey
                                                  ] /
                                                    row.allUsers?.otherData?.[
                                                      otherRowKey
                                                    ]) *
                                                    1000,
                                                ) / 10
                                              ).toFixed(1)}%`
                                            : '-'}
                                      </TableCell>

                                      <TableCell
                                        align="right"
                                        style={{
                                          color:
                                            otherRowKey === 'Not specified'
                                              ? (row.cancelledTrials?.Other ??
                                                  0) -
                                                Object.values(
                                                  row.cancelledTrials
                                                    ?.otherData || {},
                                                ).reduce((t, a) => t + a, 0)
                                                ? 'black'
                                                : 'white'
                                              : row.cancelledTrials
                                                    ?.otherData?.[otherRowKey]
                                                ? 'black'
                                                : 'white',
                                          borderBottom: 0,
                                          paddingRight: 16,
                                        }}
                                        scope="row"
                                        padding="none"
                                      >
                                        {otherRowKey === 'Not specified'
                                          ? (row.cancelledTrials?.Other ?? 0) -
                                            Object.values(
                                              row.cancelledTrials?.otherData ||
                                                {},
                                            ).reduce((t, a) => t + a, 0)
                                          : row.cancelledTrials?.otherData?.[
                                              otherRowKey
                                            ] ?? 0}
                                      </TableCell>

                                      <TableCell
                                        align="right"
                                        style={{
                                          borderBottom: 0,
                                          paddingRight: 16,
                                        }}
                                        scope="row"
                                        padding="none"
                                      >
                                        {otherRowKey === 'Not specified'
                                          ? !Number.isNaN(
                                              ((row.cancelledTrials?.Other ??
                                                0) -
                                                Object.values(
                                                  row.cancelledTrials
                                                    ?.otherData || {},
                                                ).reduce((t, a) => t + a, 0)) /
                                                ((row.subscribers?.Other ?? 0) -
                                                  Object.values(
                                                    row.subscribers
                                                      ?.otherData || {},
                                                  ).reduce((t, a) => t + a, 0)),
                                            )
                                            ? `${(
                                                Math.round(
                                                  (((row.cancelledTrials
                                                    ?.Other ?? 0) -
                                                    Object.values(
                                                      row.cancelledTrials
                                                        ?.otherData || {},
                                                    ).reduce(
                                                      (t, a) => t + a,
                                                      0,
                                                    )) /
                                                    ((row.subscribers?.Other ??
                                                      0) -
                                                      Object.values(
                                                        row.subscribers
                                                          ?.otherData || {},
                                                      ).reduce(
                                                        (t, a) => t + a,
                                                        0,
                                                      ))) *
                                                    1000,
                                                ) / 10
                                              ).toFixed(1)}%`
                                            : '-'
                                          : !Number.isNaN(
                                                Number(
                                                  row.cancelledTrials
                                                    ?.otherData?.[otherRowKey] /
                                                    row.subscribers
                                                      ?.otherData?.[
                                                      otherRowKey
                                                    ],
                                                ),
                                              )
                                            ? `${(
                                                Math.round(
                                                  (row.cancelledTrials
                                                    ?.otherData?.[otherRowKey] /
                                                    row.subscribers
                                                      ?.otherData?.[
                                                      otherRowKey
                                                    ]) *
                                                    1000,
                                                ) / 10
                                              ).toFixed(1)}%`
                                            : '-'}
                                      </TableCell>

                                      <TableCell
                                        align="right"
                                        style={{
                                          color:
                                            otherRowKey === 'Not specified'
                                              ? (row.activeSubscribers?.Other ??
                                                  0) -
                                                Object.values(
                                                  row.activeSubscribers
                                                    ?.otherData || {},
                                                ).reduce((t, a) => t + a, 0)
                                                ? 'black'
                                                : 'white'
                                              : row.activeSubscribers
                                                    ?.otherData?.[otherRowKey]
                                                ? 'black'
                                                : 'white',
                                          borderBottom: 0,
                                          paddingRight: 16,
                                        }}
                                        scope="row"
                                        padding="none"
                                      >
                                        {otherRowKey === 'Not specified'
                                          ? (row.activeSubscribers?.Other ??
                                              0) -
                                            Object.values(
                                              row.activeSubscribers
                                                ?.otherData || {},
                                            ).reduce((t, a) => t + a, 0)
                                          : row.activeSubscribers?.otherData?.[
                                              otherRowKey
                                            ]}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        scope="row"
                                        padding="none"
                                        style={{
                                          borderBottom: 0,
                                          paddingRight: 16,
                                        }}
                                      >
                                        {otherRowKey === 'Not specified'
                                          ? !Number.isNaN(
                                              ((row.activeSubscribers?.Other ??
                                                0) -
                                                Object.values(
                                                  row.activeSubscribers
                                                    ?.otherData || {},
                                                ).reduce((t, a) => t + a, 0)) /
                                                ((row.subscribers?.Other ?? 0) -
                                                  Object.values(
                                                    row.subscribers
                                                      ?.otherData || {},
                                                  ).reduce((t, a) => t + a, 0)),
                                            )
                                            ? `${(
                                                Math.round(
                                                  (((row.activeSubscribers
                                                    ?.Other ?? 0) -
                                                    Object.values(
                                                      row.activeSubscribers
                                                        ?.otherData || {},
                                                    ).reduce(
                                                      (t, a) => t + a,
                                                      0,
                                                    )) /
                                                    ((row.subscribers?.Other ??
                                                      0) -
                                                      Object.values(
                                                        row.subscribers
                                                          ?.otherData || {},
                                                      ).reduce(
                                                        (t, a) => t + a,
                                                        0,
                                                      ))) *
                                                    1000,
                                                ) / 10
                                              ).toFixed(1)}%`
                                            : '-'
                                          : !Number.isNaN(
                                                Number(
                                                  row.activeSubscribers
                                                    ?.otherData?.[otherRowKey] /
                                                    row.subscribers
                                                      ?.otherData?.[
                                                      otherRowKey
                                                    ],
                                                ),
                                              )
                                            ? `${(
                                                Math.round(
                                                  (row.activeSubscribers
                                                    ?.otherData?.[otherRowKey] /
                                                    row.subscribers
                                                      ?.otherData?.[
                                                      otherRowKey
                                                    ]) *
                                                    1000,
                                                ) / 10
                                              ).toFixed(1)}%`
                                            : '-'}
                                      </TableCell>

                                      {/*  */}
                                      <TableCell
                                        align="right"
                                        style={{
                                          color:
                                            otherRowKey === 'Not specified'
                                              ? (row.refunds?.Other ?? 0) -
                                                Object.values(
                                                  row.refunds?.otherData || {},
                                                ).reduce((t, a) => t + a, 0)
                                                ? 'black'
                                                : 'white'
                                              : row.refunds?.otherData?.[
                                                    otherRowKey
                                                  ]
                                                ? 'black'
                                                : 'white',
                                          borderBottom: 0,
                                          paddingRight: 16,
                                        }}
                                        scope="row"
                                        padding="none"
                                      >
                                        {otherRowKey === 'Not specified'
                                          ? (row.refunds?.Other ?? 0) -
                                            Object.values(
                                              row.refunds?.otherData || {},
                                            ).reduce((t, a) => t + a, 0)
                                          : row.refunds?.otherData?.[
                                              otherRowKey
                                            ]}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        scope="row"
                                        padding="none"
                                        style={{
                                          borderBottom: 0,
                                          paddingRight: 16,
                                        }}
                                      >
                                        {otherRowKey === 'Not specified'
                                          ? !Number.isNaN(
                                              ((row.refunds?.Other ?? 0) -
                                                Object.values(
                                                  row.refunds?.otherData || {},
                                                ).reduce((t, a) => t + a, 0)) /
                                                ((row.activeSubscribers
                                                  ?.Other ?? 0) -
                                                  Object.values(
                                                    row.activeSubscribers
                                                      ?.otherData || {},
                                                  ).reduce((t, a) => t + a, 0) +
                                                  ((row.refunds?.Other ?? 0) -
                                                    Object.values(
                                                      row.refunds?.otherData ||
                                                        {},
                                                    ).reduce(
                                                      (t, a) => t + a,
                                                      0,
                                                    ))),
                                            )
                                            ? `${(
                                                Math.round(
                                                  (((row.refunds?.Other ?? 0) -
                                                    Object.values(
                                                      row.refunds?.otherData ||
                                                        {},
                                                    ).reduce(
                                                      (t, a) => t + a,
                                                      0,
                                                    )) /
                                                    ((row.activeSubscribers
                                                      ?.Other ?? 0) -
                                                      Object.values(
                                                        row.activeSubscribers
                                                          ?.otherData || {},
                                                      ).reduce(
                                                        (t, a) => t + a,
                                                        0,
                                                      ) +
                                                      ((row.refunds?.Other ??
                                                        0) -
                                                        Object.values(
                                                          row.refunds
                                                            ?.otherData || {},
                                                        ).reduce(
                                                          (t, a) => t + a,
                                                          0,
                                                        )))) *
                                                    1000,
                                                ) / 10
                                              ).toFixed(1)}%`
                                            : '-'
                                          : !Number.isNaN(
                                                Number(
                                                  row.refunds?.otherData?.[
                                                    otherRowKey
                                                  ] /
                                                    (row.refunds?.otherData?.[
                                                      otherRowKey
                                                    ] ??
                                                      0 +
                                                        row.activeSubscribers
                                                          ?.otherData?.[
                                                          otherRowKey
                                                        ] ??
                                                      0),
                                                ),
                                              )
                                            ? `${(
                                                Math.round(
                                                  (row.refunds?.otherData?.[
                                                    otherRowKey
                                                  ] /
                                                    (row.refunds?.otherData?.[
                                                      otherRowKey
                                                    ] ??
                                                      0 +
                                                        row.activeSubscribers
                                                          ?.otherData?.[
                                                          otherRowKey
                                                        ] ??
                                                      0)) *
                                                    1000,
                                                ) / 10
                                              ).toFixed(1)}%`
                                            : '-'}
                                      </TableCell>

                                      {/*  */}

                                      <TableCell
                                        scope="row"
                                        padding="none"
                                        style={{
                                          borderBottom: 0,
                                          paddingLeft: 16,
                                          paddingRight: 16,
                                        }}
                                      >
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                      </TableCell>
                                    </TableRow>
                                  ))
                              : null}
                          </>
                        ))}
                        <TableRow key={`${row.day}_total`} hover>
                          <TableCell
                            scope="row"
                            padding="none"
                            style={{
                              paddingBottom: 16,
                              paddingLeft: 16,
                              paddingRight: 16,
                              borderTop: `thick double ${totalBorderColor}`,
                            }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp; Total, of (
                            {row.accountsCreated}) signups
                          </TableCell>

                          {/* <TableCell
                            scope="row"
                            padding="none"
                            align="right"
                            style={{
                              paddingBottom: 16,
                              fontWeight: 'bold',
                              paddingRight: 16,
                            }}
                          >
                            {Object.values(row.provisionalUsers).reduce(
                              (t, a) => t + a,
                              0,
                            )}
                          </TableCell>

                          <TableCell
                            scope="row"
                            padding="none"
                            align="right"
                            style={{
                              paddingBottom: 16,
                              fontWeight: 'bold',
                              paddingRight: 16,
                            }}
                          >
                            <AverageConv
                              numerator={Object.values(
                                row.provisionalUsersWhoSignedUp,
                              )}
                              denominator={Object.values(row.provisionalUsers)}
                            />
                          </TableCell> */}

                          <TableCell
                            scope="row"
                            padding="none"
                            align="right"
                            style={{
                              paddingBottom: 16,
                              fontWeight: 'bold',
                              paddingRight: 16,
                            }}
                          >
                            {Object.values(row.allUsers)
                              .filter((i) => typeof i === 'number')
                              .reduce((t, a) => t + a, 0)}
                          </TableCell>

                          <TableCell
                            scope="row"
                            padding="none"
                            align="right"
                            style={{
                              paddingBottom: 16,
                              fontWeight: 'bold',
                              paddingRight: 16,
                            }}
                          >
                            {Object.values(row.subscribers)
                              .filter((i) => typeof i === 'number')
                              .reduce((t, a) => t + a, 0)}
                          </TableCell>

                          <TableCell
                            scope="row"
                            padding="none"
                            align="right"
                            style={{
                              paddingBottom: 16,
                              fontWeight: 'bold',
                              paddingRight: 16,
                            }}
                          >
                            <AverageConv
                              numerator={Object.values(row.subscribers).filter(
                                (i) => typeof i === 'number',
                              )}
                              denominator={Object.values(row.allUsers).filter(
                                (i) => typeof i === 'number',
                              )}
                            />
                          </TableCell>

                          <TableCell
                            scope="row"
                            padding="none"
                            align="right"
                            style={{
                              paddingBottom: 16,
                              fontWeight: 'bold',
                              paddingRight: 16,
                            }}
                          >
                            {Object.values(row.cancelledTrials)
                              .filter((i) => typeof i === 'number')
                              .reduce((t, a) => t + a, 0)}
                          </TableCell>

                          <TableCell
                            scope="row"
                            padding="none"
                            align="right"
                            style={{
                              paddingBottom: 16,
                              fontWeight: 'bold',
                              paddingRight: 16,
                            }}
                          >
                            <AverageConv
                              numerator={Object.values(
                                row.cancelledTrials,
                              ).filter((i) => typeof i === 'number')}
                              denominator={Object.values(
                                row.subscribers,
                              ).filter((i) => typeof i === 'number')}
                            />
                          </TableCell>

                          <TableCell
                            scope="row"
                            padding="none"
                            align="right"
                            style={{
                              paddingBottom: 16,
                              fontWeight: 'bold',
                              paddingRight: 16,
                            }}
                          >
                            {Object.values(row.activeSubscribers)
                              .filter((i) => typeof i === 'number')
                              .reduce((t, a) => t + a, 0)}
                          </TableCell>

                          <TableCell
                            scope="row"
                            padding="none"
                            align="right"
                            style={{
                              paddingBottom: 16,
                              fontWeight: 'bold',
                              paddingRight: 16,
                            }}
                          >
                            <AverageConv
                              numerator={Object.values(
                                row.activeSubscribers,
                              ).filter((i) => typeof i === 'number')}
                              denominator={Object.values(
                                row.subscribers,
                              ).filter((i) => typeof i === 'number')}
                            />
                          </TableCell>

                          {/*  */}
                          <TableCell
                            scope="row"
                            padding="none"
                            align="right"
                            style={{
                              paddingBottom: 16,
                              fontWeight: 'bold',
                              paddingRight: 16,
                            }}
                          >
                            {Object.values(row.refunds)
                              .filter((i) => typeof i === 'number')
                              .reduce((t, a) => t + a, 0)}
                          </TableCell>

                          <TableCell
                            scope="row"
                            padding="none"
                            align="right"
                            style={{
                              paddingBottom: 16,
                              fontWeight: 'bold',
                              paddingRight: 16,
                            }}
                          >
                            <AverageConv
                              numerator={Object.values(row.refunds).filter(
                                (i) => typeof i === 'number',
                              )}
                              denominator={[
                                ...Object.values(row.activeSubscribers).filter(
                                  (i) => typeof i === 'number',
                                ),
                                ...Object.values(row.refunds).filter(
                                  (i) => typeof i === 'number',
                                ),
                              ]}
                            />
                          </TableCell>

                          {/*  */}

                          <TableCell
                            scope="row"
                            padding="none"
                            align="right"
                            style={{
                              paddingBottom: 16,
                              fontWeight: 'bold',
                              paddingRight: 16,
                            }}
                          >
                            {row.newSessions}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </ResponsiveContainer>
        </ReferralChartContainer>
      )}
    </StyledCard>
  );
}
