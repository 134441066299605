import * as MUI from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkIcon from '@material-ui/icons/Link';
import PauseCircleFilled from '@material-ui/icons/PauseCircleFilled';
import { Alert } from '@material-ui/lab';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import { useNotify } from 'react-admin';
import { Controller, useForm } from 'react-hook-form';
import { useFragment, useMutation } from 'react-relay';

import IPAText from '../../components/IPAText';
import PracticesSelect from '../../components/PracticesSelect';
import Reorderable from '../../components/Reorderable';
import {
  DeleteButton,
  LoadingButton,
} from '../../components/UserJourney/Buttons';
import ConversationSelect from './ConversationSelect';
import RadioField from './RadioField';
import SkillLessonSelect from './SkillLessonSelect';
import VideoSelect from './VideoSelect';

const SkillLessonsEditor = ({ skill }: any) => {
  const data = useFragment(
    graphql`
      fragment SkillLessonsEditor_skill on Skill {
        id

        skillLessons {
          id
          order
          ...SkillLessonsEditor_skillLesson
        }
      }
    `,
    skill,
  );

  const originalSkillLessonIDs = React.useMemo(
    () =>
      Object.fromEntries(
        data.skillLessons.map((skillLesson: any) => [skillLesson.id, true]),
      ),
    [],
  );

  const [clientOrderedSkillLessons, setClientOrderedSkillLessons] =
    React.useState(data.skillLessons);

  React.useEffect(() => {
    setClientOrderedSkillLessons(
      data.skillLessons.filter((skillLesson: any) => skillLesson),
    );
  }, [data.skillLessons]);

  const [commitReorderMutation, _isReorderMutationInFlight] = useMutation(
    graphql`
      mutation SkillLessonsEditor_Reorder_Mutation($newOrder: [ID!]!) {
        reorderSkillLessons(newOrder: $newOrder) {
          ...SkillLessonsEditor_skill
        }
      }
    `,
  );

  const onSaveOrder = (ids) => {
    commitReorderMutation({
      variables: {
        newOrder:
          ids ??
          clientOrderedSkillLessons.map((skillLesson: any) => skillLesson.id),
      },
    });
  };

  return (
    <MUI.Box style={{ marginBottom: 30, width: '100%' }}>
      <MUI.Typography variant="h6" gutterBottom>
        Skill Lessons
      </MUI.Typography>

      <Reorderable
        items={data.skillLessons}
        onSaveOrder={onSaveOrder}
        resourceName="skill lesson"
        droppableID="skillLessonList"
        renderItem={(skillLesson: any, index: any, dragHandleProps: any) => (
          <SkillLesson
            skillLesson={skillLesson}
            index={index}
            defaultExpanded={!originalSkillLessonIDs[skillLesson.id]}
            dragHandleProps={dragHandleProps}
          />
        )}
        renderNewItem={(resetNewItem: any) => (
          <NewSkillLesson skillID={data.id} reset={resetNewItem} />
        )}
      />
    </MUI.Box>
  );
};

const SKILL_LESSON_TYPES = {
  LESSON: 'LESSON',
  IDIOM: 'IDIOM',
  FOCUS_WORD: 'FOCUS_WORD',
  CONVERSATION: 'CONVERSATION',
  LINKED_SKILL_LESSON: 'LINKED_SKILL_LESSON',
};

const SkillLessonCard = ({
  index,
  skillLesson,
  errorMessage,
  onSubmit,
  onDelete,
  dragHandleProps,
  defaultExpanded,
  variant = 'card', // or 'inline'
}: any) => {
  const notify = useNotify();
  const theme = MUI.useTheme();

  const defaultPracticeTextIDs = React.useMemo(
    () => (skillLesson?.practiceTexts ?? []).map((p: any) => p.id),
    [skillLesson],
  );

  const { register, handleSubmit, control, watch } = useForm({
    defaultValues: {
      type: skillLesson?.type,
      videoID: skillLesson?.video?.id,
      conversationID: skillLesson?.conversation?.id,
      skillLessonID: skillLesson?.linkedSkillLesson?.id,
      numberPracticeSets: skillLesson?.numberPracticeSets,
      active: skillLesson?.active,
      practiceIDs: defaultPracticeTextIDs,
    },
  });

  const formFields = watch();

  const isChanged = React.useMemo(() => {
    if (!skillLesson) {
      return false;
    }

    return (
      formFields.type !== skillLesson.type ||
      formFields.videoID !== skillLesson.video?.id ||
      formFields.conversationID !== skillLesson.conversation?.id ||
      formFields.skillLessonID !== skillLesson.linkedSkillLesson?.id ||
      // eslint-disable-next-line eqeqeq -- on purpose to avoid having to do extra checks on type
      formFields.numberPracticeSets != skillLesson.numberPracticeSets ||
      formFields.active !== skillLesson.active ||
      [...(formFields.practiceIDs ?? [])].sort().join(',') !==
        [...(defaultPracticeTextIDs ?? [])].sort().join(',')
    );
  }, [skillLesson, formFields]);

  const isNew = !skillLesson;
  const isLinkedSkillLesson =
    (skillLesson?.type === SKILL_LESSON_TYPES.LINKED_SKILL_LESSON &&
      skillLesson?.type === formFields?.type) ||
    formFields?.type === SKILL_LESSON_TYPES.LINKED_SKILL_LESSON;

  const wrapper =
    variant === 'card'
      ? (children) => (
          <MUI.Accordion
            elevation={5}
            variant={isNew ? 'elevation' : 'outlined'}
            defaultExpanded={isNew || defaultExpanded}
            style={{
              ...(!isNew &&
                !skillLesson?.active && { backgroundColor: '#fbfbfb' }),
              ...(isNew && {
                outlineColor: theme.palette.secondary.light,
                outlineWidth: 2,
                outlineStyle: 'solid',
                borderColor: 'transparent',
              }),
            }}
          >
            {!isNew && (
              <MUI.AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <MUI.Box
                  display="flex"
                  gridColumnGap={10}
                  alignItems="center"
                  style={{ width: '100%' }}
                >
                  <div
                    style={{
                      marginLeft: -3,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    {...dragHandleProps}
                  >
                    {isNew ? (
                      <MUI.Tooltip title="Save skill lesson to reorder.">
                        <DragIndicatorIcon color="disabled" />
                      </MUI.Tooltip>
                    ) : (
                      <DragIndicatorIcon />
                    )}
                  </div>
                  <MUI.Box
                    flexGrow={1}
                    display="flex"
                    flexDirection="row"
                    style={{ width: '100%' }}
                    justifyContent="space-between"
                  >
                    <MUI.Typography
                      style={{ marginBottom: 0 }}
                      variant="body1"
                      gutterBottom
                    >
                      <MUI.Typography
                        color="textSecondary"
                        style={{
                          display: 'inline',
                          textTransform: 'capitalize',
                        }}
                      >
                        <MUI.Box
                          display="inline-block"
                          marginRight={2}
                          minWidth={21}
                          textAlign="right"
                        >
                          {index + 1}
                        </MUI.Box>
                        <MUI.Box
                          display="inline-block"
                          marginRight={2}
                          minWidth={20}
                          textAlign="right"
                        >
                          {skillLesson?.active ? (
                            <MUI.Tooltip title="Active" placement="right">
                              <CheckCircleIcon
                                htmlColor="#00c853"
                                fontSize="small"
                                style={{ verticalAlign: 'text-bottom' }}
                              />
                            </MUI.Tooltip>
                          ) : (
                            <MUI.Tooltip title="Inactive" placement="right">
                              <PauseCircleFilled
                                color="disabled"
                                fontSize="small"
                                style={{ verticalAlign: 'text-bottom' }}
                              />
                            </MUI.Tooltip>
                          )}
                        </MUI.Box>
                        <MUI.Box
                          display="inline-block"
                          marginRight={3}
                          minWidth={135}
                          textAlign="left"
                        >
                          {(
                            skillLesson?.type &&
                            SKILL_LESSON_TYPES[
                              skillLesson.type as keyof typeof SKILL_LESSON_TYPES
                            ]
                          )
                            ?.replaceAll('_', ' ')
                            ?.toLowerCase() || ''}
                        </MUI.Box>
                      </MUI.Typography>
                      <IPAText
                        text={
                          skillLesson?.video?.title ??
                          skillLesson?.conversation?.name ??
                          skillLesson?.linkedSkillLesson?.video?.title ??
                          skillLesson?.linkedSkillLesson?.conversation?.name
                        }
                      />{' '}
                    </MUI.Typography>

                    <MUI.Typography
                      style={{ marginBottom: 0 }}
                      variant="body1"
                      gutterBottom
                      onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        navigator.clipboard
                          .writeText(String(skillLesson?.id))
                          .then(() => {
                            notify(`Copied ${skillLesson?.id}`);
                          })
                          .catch(() => {
                            notify(`Error copying the id`, 'warning');
                          });
                      }}
                    >
                      ID: {skillLesson?.id}
                    </MUI.Typography>
                  </MUI.Box>
                </MUI.Box>
              </MUI.AccordionSummary>
            )}

            <MUI.AccordionDetails style={{ paddingTop: 0 }}>
              {children}
            </MUI.AccordionDetails>
          </MUI.Accordion>
        )
      : (children) => children;

  return wrapper(
    <MUI.Box
      display="flex"
      gridColumnGap={10}
      alignItems="flex-start"
      marginTop={isNew ? 2 : 0}
      width="100%"
    >
      {variant === 'card' && (
        <div style={{ marginLeft: -3, opacity: isNew ? 100 : 0 }}>
          {isNew ? (
            <MUI.Tooltip title="Save skill lesson to reorder.">
              <DragIndicatorIcon color="disabled" />
            </MUI.Tooltip>
          ) : (
            <DragIndicatorIcon />
          )}
        </div>
      )}

      <MUI.Box flexGrow={1}>
        {isNew && (
          <MUI.Typography
            color="textSecondary"
            style={{ fontSize: 14, marginBottom: 12 }}
            gutterBottom
          >
            New Skill Lesson
          </MUI.Typography>
        )}

        <MUI.Collapse in={!!errorMessage}>
          <MUI.Box marginTop={1}>
            <Alert severity="error">{errorMessage}</Alert>
          </MUI.Box>
        </MUI.Collapse>

        <MUI.TextField
          label="Type"
          {...register('type')}
          select
          required
          variant="filled"
          size="small"
          style={{ width: '20ch' }}
          defaultValue={isNew ? 'LESSON' : skillLesson.type}
          margin="normal"
        >
          {Object.entries(SKILL_LESSON_TYPES).map(([type, label]) => (
            <MUI.MenuItem
              key={type}
              value={type}
              style={{ textTransform: 'capitalize' }}
            >
              <MUI.Typography
                variant="body1"
                style={{ textTransform: 'capitalize' }}
              >
                {label?.replaceAll('_', ' ')?.toLowerCase()}
              </MUI.Typography>
            </MUI.MenuItem>
          ))}
        </MUI.TextField>

        <MUI.Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gridColumnGap={30}
          style={{
            display:
              ([
                SKILL_LESSON_TYPES.LESSON,
                SKILL_LESSON_TYPES.IDIOM,
                SKILL_LESSON_TYPES.FOCUS_WORD,
              ].includes(skillLesson?.type) &&
                skillLesson?.type === formFields?.type) ||
              [
                SKILL_LESSON_TYPES.LESSON,
                SKILL_LESSON_TYPES.IDIOM,
                SKILL_LESSON_TYPES.FOCUS_WORD,
              ].includes(formFields?.type)
                ? 'flex'
                : 'none',
          }}
        >
          <Controller
            control={control}
            name="videoID"
            defaultValue={isNew ? null : skillLesson.video?.id}
            render={({ field: { onChange, onBlur, value } }) => (
              <VideoSelect onChange={onChange} onBlur={onBlur} value={value} />
            )}
          />
          <MUI.Divider flexItem orientation="vertical" />
          <MUI.Tooltip title="Opens in a new tab.">
            <MUI.Button
              variant="outlined"
              color="secondary"
              size="small"
              href="/#/g/video-groups/create"
              target="_blank"
            >
              Create New Video
            </MUI.Button>
          </MUI.Tooltip>
        </MUI.Box>

        <MUI.Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gridColumnGap={30}
          style={{
            display:
              (skillLesson?.type === SKILL_LESSON_TYPES.CONVERSATION &&
                skillLesson?.type === formFields?.type) ||
              formFields?.type === SKILL_LESSON_TYPES.CONVERSATION
                ? 'flex'
                : 'none',
          }}
        >
          <Controller
            control={control}
            name="conversationID"
            defaultValue={isNew ? null : skillLesson.conversation?.id}
            render={({ field: { onChange, onBlur, value } }) => (
              <ConversationSelect
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <MUI.Divider flexItem orientation="vertical" />
          <MUI.Tooltip title="Opens in a new tab.">
            <MUI.Button
              variant="outlined"
              color="secondary"
              size="small"
              href="/#/content/conversation/create"
              target="_blank"
            >
              Create New Conversation
            </MUI.Button>
          </MUI.Tooltip>
        </MUI.Box>

        {isLinkedSkillLesson && (
          <MUI.Box>
            <Controller
              control={control}
              name="skillLessonID"
              defaultValue={isNew ? null : skillLesson?.linkedSkillLesson?.id}
              render={({ field: { onChange, onBlur, value } }) => (
                <SkillLessonSelect
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
              )}
            />

            {skillLesson?.linkedSkillLesson &&
              skillLesson?.linkedSkillLesson?.id ===
                formFields.skillLessonID && (
                <div>
                  <MUI.Accordion
                    variant="outlined"
                    TransitionProps={{ unmountOnExit: true }}
                  >
                    <MUI.AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Edit Linked Skill Lesson
                    </MUI.AccordionSummary>
                    <MUI.AccordionDetails>
                      <MUI.Box width="100%">
                        <MUI.Box marginBottom={2}>
                          <Alert severity="warning" icon={<LinkIcon />}>
                            This skill lesson belongs to another skill. Editing
                            it will affect the owning skill.
                          </Alert>
                        </MUI.Box>
                        {isNew ? null : (
                          <SkillLesson
                            index={0}
                            skillLesson={skillLesson?.linkedSkillLesson}
                            variant="inline"
                          />
                        )}
                      </MUI.Box>
                    </MUI.AccordionDetails>
                  </MUI.Accordion>
                </div>
              )}
          </MUI.Box>
        )}

        <MUI.Box marginTop={4} paddingBottom={1}>
          <MUI.FormLabel focused={false} disabled={isNew}>
            Practice Texts
          </MUI.FormLabel>

          {isLinkedSkillLesson && (
            <MUI.Box marginTop={1} maxWidth={700}>
              <Alert severity="warning" icon={<LinkIcon />}>
                This skill lesson references another skill lesson. Adding
                practice texts directly to this skill lesson will prevent the
                linked skill lesson&apos;s practice texts from being drawn from
                when used in this skill lesson.
              </Alert>
            </MUI.Box>
          )}

          <MUI.Box>
            <Controller
              control={control}
              name="practiceIDs"
              defaultValue={isNew ? null : defaultPracticeTextIDs}
              render={({ field: { onChange, onBlur, value } }) => (
                <PracticesSelect
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isNew}
                />
              )}
            />
          </MUI.Box>

          {isNew && (
            <MUI.Box maxWidth="65ch" marginBottom={1} marginTop={0}>
              <MUI.Typography variant="body2">
                Practices can be added after saving.
              </MUI.Typography>
            </MUI.Box>
          )}
        </MUI.Box>

        <MUI.Box marginTop={4}>
          <MUI.FormLabel focused={false}>Number of Practice Sets</MUI.FormLabel>
          <Controller
            control={control}
            name="numberPracticeSets"
            defaultValue={isNew ? 2 : skillLesson.numberPracticeSets}
            render={({ field: { onChange, value } }) => (
              <MUI.Box marginLeft={3}>
                <MUI.Slider
                  valueLabelDisplay="auto"
                  step={null}
                  marks={[
                    {
                      value: -3,
                      label: 'Default',
                    },
                    ...Array.from({ length: 10 }, (_, i) => ({
                      value: i,
                      label: i,
                    })),
                  ]}
                  min={-3}
                  max={9}
                  valueLabelFormat={(v) => (v < 0 ? '–' : v)}
                  value={value == null ? -5 : value}
                  onChange={(_, value) =>
                    onChange((value as number) < 0 ? null : value)
                  }
                  style={{ width: '35ch' }}
                />
              </MUI.Box>
            )}
          />
          <MUI.FormHelperText>
            Number of practice sets to generate. Each practice set has 10
            practice texts.{' '}
            {isLinkedSkillLesson &&
              ` Defaults to the linked skill lesson's configuration.`}
          </MUI.FormHelperText>
        </MUI.Box>

        <MUI.Box marginTop={4}>
          <Controller
            control={control}
            name="active"
            defaultValue={isNew ? false : skillLesson.active}
            render={({ field: { onChange, value } }) => (
              <RadioField
                label="Status"
                value={value}
                onChange={(e: any) => onChange(e.target.value === 'true')}
                disabled={isNew}
                options={[
                  {
                    value: false,
                    label: 'Inactive',
                    helperText:
                      "Won't be included in future lesson plans, but will remain in already generated plans.",
                  },
                  {
                    value: true,
                    label: 'Active',
                    helperText: 'Eligible for inclusion in new lesson plans.',
                  },
                ]}
              />
            )}
          />

          {isNew && (
            <MUI.Box maxWidth="65ch" marginBottom={1} marginTop={2}>
              <MUI.Divider style={{ marginBottom: 5 }} />
              <MUI.Typography variant="body2">
                This skill lesson will be inactive when saved, and can be
                converted to active after saving.
              </MUI.Typography>
            </MUI.Box>
          )}
        </MUI.Box>

        <MUI.Box marginTop={3} paddingTop={1} gridColumnGap={10} display="flex">
          <LoadingButton
            variant="contained"
            color="secondary"
            size="small"
            type="submit"
            isLoading={false}
            disabled={!isNew && !isChanged}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </LoadingButton>

          {!isNew && (
            <DeleteButton
              onDelete={onDelete}
              content="Are you sure you want to delete this skill lesson? This cannot be undone."
            />
          )}
        </MUI.Box>
      </MUI.Box>
    </MUI.Box>,
  );
};

const NewSkillLesson = ({ skillID, reset }: any) => {
  const [commitMutation, _isMutationInFlight] = useMutation(graphql`
    mutation SkillLessonsEditor_CreateSkillLesson_Mutation(
      $skillID: ID
      $type: SkillLessonType
      $videoID: ID
      $conversationID: ID
      $skillLessonID: ID
      $numberPracticeSets: Int
    ) {
      raCreateSkillLesson(
        skillID: $skillID
        type: $type
        videoID: $videoID
        conversationID: $conversationID
        skillLessonID: $skillLessonID
        numberPracticeSets: $numberPracticeSets
      ) {
        skill {
          skillLessons {
            ...SkillLessonsEditor_skillLesson
          }
        }
      }
    }
  `);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const onSubmit = (data: any) => {
    if (!data.conversationID && !data.videoID && !data.skillLessonID) {
      setErrorMessage(
        'Either video or conversation or linked skill lesson must be selected.',
      );
      return;
    }

    if (data.type === SKILL_LESSON_TYPES.CONVERSATION && !data.conversationID) {
      setErrorMessage('Please select a conversation for this Skill Lesson.');
      return;
    }

    if (
      [
        SKILL_LESSON_TYPES.LESSON,
        SKILL_LESSON_TYPES.IDIOM,
        SKILL_LESSON_TYPES.FOCUS_WORD,
      ].includes(data.type) &&
      !data.videoID
    ) {
      setErrorMessage('Please select a conversation for this Skill Lesson.');
      return;
    }

    if (
      data.type === SKILL_LESSON_TYPES.LINKED_SKILL_LESSON &&
      !data.skillLessonID
    ) {
      setErrorMessage(
        'Please select a linked skill lesson for this Skill Lesson.',
      );
      return;
    }

    setErrorMessage(null);

    commitMutation({
      variables: {
        skillID,
        type: data.type,
        videoID: [
          SKILL_LESSON_TYPES.LESSON,
          SKILL_LESSON_TYPES.IDIOM,
          SKILL_LESSON_TYPES.FOCUS_WORD,
        ].includes(data.type)
          ? data.videoID
          : null,
        conversationID:
          data.type === SKILL_LESSON_TYPES.CONVERSATION
            ? data.conversationID
            : null,
        skillLessonID:
          data.type === SKILL_LESSON_TYPES.LINKED_SKILL_LESSON
            ? data.skillLessonID
            : null,
        numberPracticeSets: Number.isNaN(data.numberPracticeSets)
          ? null
          : parseInt(data.numberPracticeSets, 10),
      },
      onCompleted: (response, errors) => {
        if (errors && errors.length > 0) {
          setErrorMessage(errors[0].message);
          return;
        }

        reset();
      },
    });
  };

  return <SkillLessonCard errorMessage={errorMessage} onSubmit={onSubmit} />;
};

const SkillLesson = ({
  skillLesson,
  index,
  dragHandleProps,
  defaultExpanded,
  variant,
}: {
  skillLesson: any;
  index: any;
  dragHandleProps?: any;
  defaultExpanded?: boolean;
  variant?: 'card' | 'inline';
}) => {
  const data = useFragment(
    graphql`
      fragment SkillLessonsEditor_skillLesson on SkillLesson
      @argumentDefinitions(
        loadLinkedSkillLesson: { type: "Boolean", defaultValue: true }
      ) {
        id
        type
        active

        numberPracticeSets

        conversation {
          id
          name
        }

        video {
          id
          title
        }

        linkedSkillLesson {
          id
          video {
            title
          }
          conversation {
            name
          }

          numberPracticeSets

          practiceTexts: allPracticeTexts {
            id
            text
            coachSample
            aiCoachSample
            isSentence
          }

          ...SkillLessonsEditor_skillLesson
            @arguments(loadLinkedSkillLesson: false)
            @include(if: $loadLinkedSkillLesson)
        }

        practiceTexts: allPracticeTexts {
          id
          text
          coachSample
          aiCoachSample
          isSentence
        }
      }
    `,
    skillLesson,
  );

  const [commitMutation, _isMutationInFlight] = useMutation(graphql`
    mutation SkillLessonsEditor_SkillLesson_Mutation(
      $id: ID
      $type: SkillLessonType
      $videoID: ID
      $conversationID: ID
      $skillLessonID: ID
      $numberPracticeSets: Int
      $active: Boolean
      $practiceTextIDs: [ID!]
    ) {
      raUpdateSkillLesson(
        id: $id
        type: $type
        videoID: $videoID
        conversationID: $conversationID
        skillLessonID: $skillLessonID
        numberPracticeSets: $numberPracticeSets
        active: $active
        practiceTextIDs: $practiceTextIDs
      ) {
        ...SkillLessonsEditor_skillLesson
      }
    }
  `);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const onSave = (formData: any) => {
    if (
      !formData.conversationID &&
      !formData.videoID &&
      !formData.skillLessonID
    ) {
      setErrorMessage(
        'Either video or conversation or linked skill lesson must be selected.',
      );
      return;
    }

    if (
      formData.type === SKILL_LESSON_TYPES.CONVERSATION &&
      !formData.conversationID
    ) {
      setErrorMessage('Please select a conversation for this Skill Lesson.');
      return;
    }

    if (
      [
        SKILL_LESSON_TYPES.LESSON,
        SKILL_LESSON_TYPES.IDIOM,
        SKILL_LESSON_TYPES.FOCUS_WORD,
      ].includes(formData.type) &&
      !formData.videoID
    ) {
      setErrorMessage('Please select a conversation for this Skill Lesson.');
      return;
    }

    if (
      formData.type === SKILL_LESSON_TYPES.LINKED_SKILL_LESSON &&
      !formData.skillLessonID
    ) {
      setErrorMessage(
        'Please select a linked skill lesson for this Skill Lesson.',
      );
      return;
    }

    commitMutation({
      variables: {
        id: data.id,
        type: formData.type,
        videoID: [
          SKILL_LESSON_TYPES.LESSON,
          SKILL_LESSON_TYPES.IDIOM,
          SKILL_LESSON_TYPES.FOCUS_WORD,
        ].includes(formData.type)
          ? formData.videoID
          : null,
        conversationID:
          formData.type === SKILL_LESSON_TYPES.CONVERSATION
            ? formData.conversationID
            : null,

        skillLessonID:
          formData.type === SKILL_LESSON_TYPES.LINKED_SKILL_LESSON
            ? formData.skillLessonID
            : null,

        numberPracticeSets: Number.isNaN(formData.numberPracticeSets)
          ? null
          : parseInt(formData.numberPracticeSets, 10),
        active: formData.active,
        practiceTextIDs: formData.practiceIDs,
      },
    });
  };

  const [commitDeleteMutation, _isDeleteMutationInFlight] = useMutation(graphql`
    mutation SkillLessonsEditor_SkillLessonDelete_Mutation($id: ID) {
      raDeleteSkillLesson(id: $id) {
        nodeID @deleteRecord
      }
    }
  `);

  const [cachedSkillLesson, setCachedSkillLesson] = React.useState<
    null | typeof data
  >(null);

  const onDelete = () => {
    setCachedSkillLesson(data);
    commitDeleteMutation({
      variables: {
        id: data.id,
      },
    });
  };

  return (
    <SkillLessonCard
      skillLesson={data || cachedSkillLesson}
      index={index}
      errorMessage={errorMessage}
      onSubmit={onSave}
      onDelete={onDelete}
      dragHandleProps={dragHandleProps}
      defaultExpanded={defaultExpanded}
      variant={variant}
    />
  );
};

export default SkillLessonsEditor;
